import React from 'react';
import { ReactComponent as Energy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as Ciudades } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as Inmobiliaria } from 'Icons/soluciones/inmobiliaria.svg';
import { ReactComponent as Movilidad } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as Agua } from 'Icons/soluciones/agua.svg';
import { ReactComponent as Social } from 'Icons/soluciones/social.svg';
import { ReactComponent as Capital } from 'Icons/soluciones/capital.svg';
import { ReactComponent as PlusIcon } from 'Icons/iconplusbig.svg';
import { ReactComponent as MinusIcon } from 'Icons/iconlessbig.svg';
import { ReactComponent as ChevronIcon } from 'Icons/chevron_down-18.svg';
import { ReactComponent as ArrowUpIcon } from 'Icons/flechaup.svg';
import { ReactComponent as ArrowDownIcon } from 'Icons/flechadown.svg';


import { ReactComponent as LinkedinIcon } from 'Icons/social_media/linkedin.svg';
import { ReactComponent as YoutubeIcon } from 'Icons/social_media/youtube.svg';
import { ReactComponent as InstagramIcon } from 'Icons/social_media/instagram.svg';
import { ReactComponent as TwitterIcon } from 'Icons/social_media/twitter-x.svg';
import { ReactComponent as FacebookIcon } from 'Icons/social_media/facebook.svg';
import { ReactComponent as TiktokIcon } from 'Icons/social_media/tiktok.svg'



const IconsMap = {
  ENERGÍA: <Energy width="18" height="18" viewBox="0 0 24 24" />,
  ENERGY: <Energy width="18" height="18" viewBox="0 0 24 24" />,

  TRANSPORTE: <Movilidad width="18" height="18" viewBox="0 0 24 24" />,
  TRANSPORT: <Movilidad width="18" height="18" viewBox="0 0 24 24" />,

  AGUA: <Agua width="18" height="18" viewBox="0 0 24 24" />,
  WATER: <Agua width="18" height="18" viewBox="0 0 24 24" />,

  SOCIALES: <Social width="18" height="18" viewBox="0 0 24 24" />,
  SOCIAL: <Social width="18" height="18" viewBox="0 0 24 24" />,

  CIUDADES: <Ciudades width="18" height="18" viewBox="0 0 24 24" />,
  CITIES: <Ciudades width="18" height="18" viewBox="0 0 24 24" />,

  INMOBILIARIA: <Inmobiliaria width="18" height="18" viewBox="0 0 24 24" />,
  REALESTATE: <Inmobiliaria width="18" height="18" viewBox="0 0 24 24" />,

  FINANCIERAS: <Capital width="18" height="18" viewBox="0 0 24 24" />,
  FINANCIAL: <Capital width="18" height="18" viewBox="0 0 24 24" />,

  Energia: <Energy width="18" height="18" viewBox="0 0 24 24" />,
  Transporte: <Movilidad width="18" height="18" viewBox="0 0 24 24" />,
  Agua: <Agua width="18" height="18" viewBox="0 0 24 24" />,
  Social: <Social width="18" height="18" viewBox="0 0 24 24" />,
  Ciudades: <Ciudades width="18" height="18" viewBox="0 0 24 24" />,
  Inmobiliaria: <Inmobiliaria width="18" height="18" viewBox="0 0 24 24" />,
  Capital: <Capital width="18" height="18" viewBox="0 0 24 24" />,
  EnergiaDefault:
    '/static/images/project_default/proyectos_930x600_energia.svg',
  CiudadesDefault:
    '/static/images/project_default/proyectos_930x600_ciudades.svg',
  TransporteDefault:
    '/static/images/project_default/proyectos_930x600_transportes.svg',
  AguaDefault: '/static/images/project_default/proyectos_930x600_agua.svg',
  SocialDefault: '/static/images/project_default/proyectos_930x600_social.svg',
  CapitalDefault:
    '/static/images/project_default/proyectos_930x600_financieras.svg',
  InmobiliariaDefault:
    '/static/images/project_default/proyectos_930x600_inmobiliaria.svg',
  IconPlus: <PlusIcon width="24" height="24" viewBox="0 0 24 24" />,
  IconMinus: <MinusIcon width="24" height="24" viewBox="0 0 24 24" />,
  Chevron: <ChevronIcon width="12" height="7" />,
  ArrowUp: <ArrowUpIcon width="24" height="24" viewBox="0 0 24 24" />,
  ArrowDown: <ArrowDownIcon width="24" height="24" viewBox="0 0 24 24" />,
};

const IconsSocialMedia = {

  Linkedin: <LinkedinIcon width="24" height="24" viewBox="0 0 24 24" />,
  Youtube: <YoutubeIcon width="24" height="24" viewBox="0 0 24 24" />,
  Instagram: <InstagramIcon width="24" height="24" viewBox="0 0 24 24" />,
  //Twitter: <TwitterIcon width="24" height="24" viewBox="0 0 24 24" />,
  Twitter: <TwitterIcon width="24" height="24" viewBox="0 0 512 512" />,
  TwitterLandingOwnDomain: <TwitterIcon width="12" height="12" viewBox="0 0 512 512" />,
  TwitterLandingOwnDomain_14: <TwitterIcon width="14" height="14" viewBox="0 0 512 512" />,
  Facebook: <FacebookIcon width="24" height="24" viewBox="0 0 24 24" />,
  Tiktok: <TiktokIcon width="16" height="24" viewBox="0 10 24 24" />,


}

export { IconsMap, IconsSocialMedia };
