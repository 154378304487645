// button.component.js
import React from 'react';
//import { Upload, message, Button } from 'antd';
//import { UploadOutlined } from '@ant-design/icons';

const Uploader = ({ name, label, fileList, handleChange, errorMsg }) => {

    const props = {
        accept: ".jpg, .jpeg, .png, .pdf",
        onChange: (e) => onChangeHandler(e),
        multiple: true,
    };

    const onChangeHandler = (file) => {
        //let file = [info.file];
        //let fileList = [...info.fileList];
        handleChange(file.target.files);

    };

    return (
        <div>
            <div className="upload-item">
                <label className="upload-label" htmlFor="file">{label}</label>
                <input type="file" className="upload-file" name="file" onChange={(e) => onChangeHandler(e)} accept=".jpg, .jpeg, .png, .pdf" multiple />
            </div>
            <div className="upload-list">
                {fileList.length > 0 &&
                    <>
                        {fileList.map((file, idx) => {
                            return (<p key={`file-key-${idx}`}>{file.name}</p>);
                        })}
                    </>
                }
            </div>
        </div>
    );

    //return (
    //    <div className="upload-input">
    //        <Upload {...props} fileList={fileList}>
    //            <Button type="primary" style={{ backgroundColor: 'red', borderRadius: 30, color: '#FFF', height: 25, cursor: 'pointer' }}>
    //                <UploadOutlined /> {label}
    //</Button>
    //        </Upload>
    //    </div>
    //);
};

export default Uploader;
