import React from 'react';
import './styles.scss';

import { ReactComponent as IconLeft } from 'Icons/paginatorLeft.svg';
import { ReactComponent as IconRight } from 'Icons/paginatorRight.svg';

const PaginatorSimple = ({ pagesList, actualPage, changePage, prevPage, nextPage }) => {

    if (pagesList && pagesList.length > 0) {

        return (
            <div className="module grid module-paginator">
                <nav className="slow">
                    {actualPage !== 1 &&
                        <button className="button--left" onClick={() => prevPage()}>
                            <IconLeft />
                        </button>
                    }
                    {pagesList.map((page, idx) => {
                        return (
                            <a href="#" key={`paginator-page-${idx}`} className={actualPage == page.num ? 'is-active' : ''} onClick={() => changePage(page.num)}>{page.num}</a>
                        );
                    })}
                    {actualPage !== pagesList[pagesList.length -1].num &&
                        <button className="button--right" onClick={() => nextPage()}>
                            <IconRight />
                        </button>
                    }
                </nav>
            </div>
        );
    }
    else {
        return (<div className="module grid module-paginator"></div>);
    }
};

export default PaginatorSimple;
