// button.component.js
import React from 'react';
import { Link, TagTitle } from 'React/components';

import { ReactComponent as IconDireccion } from 'Icons/direccion-16.svg';
import './Styles.scss';

const RelatedItems = ({ items }) => {

    items = [
        {
            personCargo: 'Presidente Ejecutivo de Acciona',
            icon: true,
            personName: 'José Manuel Entrecanales',
            personPicture: true,
            link: true,
        },
        {
            personCargo: 'Presidente Ejecutivo de Acciona',
            icon: true,
            personName: 'José Manuel Entrecanales',
            personPicture: true,
            link: true,
        }
    ];

    return (
        <div className="module module-related-items grid">
            <div className="grid-container">
                <TagTitle title="Tal vez te interese" />
                <div className="items-container">
                    {items.map(({ personName, personCargo, personPicture, link, icon }, item, idx) => {
                        return (
                            <div className="item" key={`key-${idx}`}>
                                {personName &&
                                    <h2 className="title--m">{personName}</h2>
                                }
                                <div className="person-content">
                                    {personPicture &&
                                        <div class="person-picture">
                                            <img src="/static/formatos/formato_personas_M.jpg" />
                                        </div>
                                    }
                                    <div className="person-cargo">
                                        {personCargo &&
                                            <h3 className="title--xs">{personCargo}
                                                {icon &&
                                                    <IconDireccion />
                                                }
                                            </h3>
                                        }
                                        {link &&
                                            <Link type="arrowLong" />
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="cta"><Link type="arrow" label="Ver todo el equipo directivo" /></div>
            </div>
        </div>
    )
};

export default RelatedItems;