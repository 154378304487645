// button.component.js
import React, { useEffect } from 'react';
import './Styles.scss';


const AnchorLink = ({ anchor }) => {
    return (
        <header>
            <a className="moduleAnchorLink" id={anchor}></a>
        </header>
    )
};

export default AnchorLink;