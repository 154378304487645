import React, { useState } from 'react';

import './Styles.scss';

const TextInput = ({ label, initValue = '', errorMsg, name, required }) => {
    const [value, setValue] = useState(initValue);

    // =================================
    // errorMsg prop should be sanitized
    // =================================

    return (
        <div className="text_input">
            <input
                className={`${value.trim().length && 'not-empty'} ${errorMsg && 'is-invalid'} body--m`}
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                name={name}
            />
            <label className="taglabel">{label} {required && <span style={{ color: 'red' }}>*</span>}</label>
            <span className="error_msg" dangerouslySetInnerHTML={{ __html: errorMsg }}></span>
        </div>
    );
};

export default TextInput;
