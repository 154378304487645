// button.component.js
import React from 'react';
import { ReactComponent as IconEquipo } from 'Icons/direccion-16.svg';

const TagTitle = ({ title, style, equipo }) => {
    return (
        <h2 className={`title--tag ${style}`}>
            {title}
            {equipo && (
                <span>
                    <IconEquipo />
                </span>
            )}
        </h2>
    );
};

export default TagTitle;
