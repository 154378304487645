import React from 'react';
import { useSlowFade } from 'React/custom_hooks';

import './style.scss';

import { TagTitle } from 'React/components';

const Cifras = ({ title, subModules, type, dataCaption, dataCaptionExtra, anchor }) => {
    const moduleRef = React.createRef();

    useSlowFade(moduleRef);

    if (type === 3) {
        return (
            <div ref={moduleRef} className="module grid cifras cifras--three slow--y">
                <div className="grid-container">
                    <TagTitle title={title} />
                </div>
                <span className="custom-anchor" id={anchor} />
                <div className="cifras-container grid-container">
                    {subModules.map((frame, idx) => {
                        const { value, unit, caption, secondValue } = frame;

                        return (
                            <div className="data-point" key={`data-point-${idx}`}>
                                <h3 className="number cifras--m">
                                    {value}
                                    <span>{unit}</span>
                                </h3>
                                <p className="title--xs">
                                    <span className="data-unit">{caption ? caption : ' '} </span>
                                </p>
                                {secondValue && (
                                    <p className="cifras--s">
                                        <span className="data-unit">{secondValue} </span>
                                    </p>
                                )}
                            </div>
                        );
                    })}
                </div>
                {dataCaption &&
                    <div className="cifras-data-footer grid-container">
                        <div className="taglabel">{dataCaption}</div>
                        <div className="taglabel">{dataCaptionExtra}</div>
                    </div>
                }
            </div>
        );
    } else {
        return (
            <div ref={moduleRef} className="module grid cifras">
                <div className="grid-container slow--y">
                    <TagTitle title={title} />
                </div>
                <span className="custom-anchor" id={anchor} />
                <div className="cifras-container grid-container">
                    {subModules.map((frame, idx) => {
                        const { value, unit, secondValue } = frame;

                        return (
                            <div className="data-point slow--y" key={`data-point-${idx}`}>
                                <h3 className="number cifras--m slow--y">{value}</h3>
                                <p className="title--xs slow--y">
                                    <span className="data-unit">{unit ? unit : ' '} </span>
                                </p>
                                {secondValue && (
                                    <p className="cifras--s slow--y">
                                        <span className="data-unit">{secondValue} </span>
                                    </p>
                                )}
                            </div>
                        );
                    })}
                </div>
                {dataCaption &&
                    <div className="cifras-data-footer grid-container slow--y">
                        <div className="taglabel slow--y">{dataCaption}</div>
                        <div className="taglabel slow--y">{dataCaptionExtra}</div>
                    </div>
                }
            </div>
        );
    }
};

export default Cifras;
