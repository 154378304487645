import React, { useState } from 'react';
import useDeviceType from '../../../../custom_hooks/useDeviceType';
import { IconsMap } from '../../../Icons/Icons';
import { TagTitle, Link } from 'React/components';
import { removeAccents } from '../../../../helpers';
import useTranslate from '../../../../custom_hooks/useTranslate';

const SolutionAreasHighlights = (props) => {
  const device = useDeviceType();
  const [expandedSolutionIndices, setExpandedSolutionIndices] = useState({});
  const [hoveredColumn, setHoveredColumn] = useState(null);

  const {
    tagTitle,
    cta,
    headers,
    solutionImages,
    _contentPath,
    culture,
  } = props;
  const subModules = props.subModules;

  let imageNames;

  if (culture === 'en') {
    imageNames = useTranslate(solutionImages);
  } else {
    imageNames = props.solutionImages;
  }

  const solutionLink = _contentPath.startsWith('/es/')
    ? '/es/soluciones/'
    : '/solutions/';

  const data = headers.map((header, idx) => ({
    header,
    subModules: subModules.map((row) => row.subModules[idx]),
    linkToSolution: `${solutionLink}${removeAccents(header)}/`,
  }));

  const toggleVisibility = (idx) => {
    setExpandedSolutionIndices((prevIndices) => ({
      ...prevIndices,
      [idx]: !prevIndices[idx],
    }));
  };

  const counters = [];

  data.forEach((item, idx) => {
    let counter = 0;

    item.subModules.forEach((areaName) => {
      const { text } = areaName;

      if (text !== '') {
        counter++;
      }
    });

    counters.push(counter);
  });

  return (
    <>
      <div className="module table-solution-highlights">
        <div className="table-highlights-title grid">
          {tagTitle && <TagTitle title={tagTitle} />}
        </div>

        <div className="highlights-table">
          <div className="container">
            {data.map((item, idx) => {
              const matchingImage = imageNames.find(
                (image) =>
                  image.name.toLowerCase() === item.header.toLowerCase()
              );
              const backgroundImage = matchingImage ? matchingImage.url : '';

              return (
                <div
                  key={idx}
                  className={`column-cell ${device === 'desktop' && hoveredColumn === idx
                    ? 'hovered'
                    : ''
                    }`}
                  onMouseEnter={() => setHoveredColumn(idx)}
                  onMouseLeave={() => setHoveredColumn(null)}
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                  }}
                >
                  <div className="column-item">
                    <div className="title-icon">
                      <span className="solution-icon">
                        {IconsMap[item.header.replace(/\s/g, '')]}
                      </span>
                      <a href={item.linkToSolution}>
                        <h1 className="link header-text">{item.header}</h1>
                      </a>
                    </div>
                  </div>

                  <div
                    className={`solution-item ${(device === 'mobile' || device === 'tablet') &&
                      expandedSolutionIndices[idx]
                      ? 'show'
                      : ''
                      }`}
                  >
                    <h3 className="item-activity-title">
                      {culture === 'es'
                        ? 'Áreas de actividad'
                        : 'Areas of activity'}
                    </h3>
                    <div className="submodules-item">
                      {item.subModules.map((areaName, areaIdx) => {
                        const { text } = areaName;
                        const textOneLine = text.replace('<br />', ' ');

                        return (
                          <React.Fragment key={areaIdx}>
                            {text && (
                              <div
                                key={areaIdx}
                                className="--left item-text"
                                dangerouslySetInnerHTML={{
                                  __html: textOneLine,
                                }}
                              ></div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>

                  <div className="submodules-count" key={idx}>
                    <p className="counter-text">{counters[idx]}   {culture === 'es'
                      ? 'áreas'
                      : 'areas'}</p>
                    {(device === 'mobile' || device === 'tablet') && (
                      <button
                        className="menu-button"
                        onClick={() => toggleVisibility(idx)}
                      >
                        {expandedSolutionIndices[idx]
                          ? IconsMap['IconMinus']
                          : IconsMap['IconPlus']}
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {cta && (
          <div className="table-highlights-title grid">
            <Link label={cta.caption} path={cta.link} type={cta.type} />
          </div>
        )}
      </div>
    </>
  );
};

export default SolutionAreasHighlights;
