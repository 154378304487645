import React from 'react';
import { ReactComponent as IconSustainability } from 'Icons/sostenible.svg';
import { ReactComponent as IconExcellence } from 'Icons/excelencia.svg';
import { ReactComponent as IconInnovation } from 'Icons/innovation.svg';

export default {
  innovacion: <IconInnovation />,
  sostenibilidad: <IconSustainability />,
  excelencia: <IconExcellence />,
};
