import React, { useState, useEffect } from 'react';
import { IconsMap } from '../../../Icons/Icons';
import { TagTitle, Link, Wysiwyg } from 'React/components';

const SolutionAreasWithText = (props) => {
  const {
    tagTitle,
    cta,
    text,
    areaSelected,
    culture
  } = props;

  const [isSolutionDetail, setIsSolutionDetail] = useState(false);

  const subModules = props.subModules;

  useEffect(() => {
    const pathname = window.location.pathname;

    if (pathname.includes('es/soluciones/energia/') || pathname.includes('/solutions/energy/')) {
      setIsSolutionDetail(true);
    } else {
      setIsSolutionDetail(false);
    }

  }, [isSolutionDetail]);

  const filteredData = {
    //TODO: Este areaSelected es un array de strings. y solo se usa para filtrar el icono. Se debe cambiar por un string
    icon: areaSelected.map(area => area.replace(/\s/g, '')),
    subModules: subModules
  };

  console.log('filteredData', filteredData.icon);

  return (

    <div className={`module table-solution-text ${isSolutionDetail ? 'optional-margin' : ''}`}>
      <div className="table-solution-text-title grid">
        {tagTitle && <TagTitle title={tagTitle} />}
      </div>

      <div className="container">
        <div className="wysiwyg-text">{text && <Wysiwyg html={text} />}</div>
        <div className="activity-areas">
          {filteredData && (
            <div className="activity-item">
              <div className="title-activity">
                <span className="icon icon--red">
                  {IconsMap[String(filteredData.icon)]}
                </span>

                <h1 className="title">
                  {culture === 'es'
                    ? 'Áreas de actividad'
                    : 'Areas of activity'}
                </h1>
              </div>

              <ul className="link area-list">
                {filteredData.subModules.map((item, areaIdx) => (
                  <div key={areaIdx} className="--left" data-id={areaIdx}>
                    {item.subModules && item.subModules.map((subModule, subIdx) => (
                      <div key={subIdx} dangerouslySetInnerHTML={{ __html: subModule.text }} />
                    ))}
                  </div>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      {cta && (
        <div className="table-solution-text-title grid">
          <Link label={cta.caption} path={cta.link} type={cta.type} />
        </div>
      )}
    </div>
  );
};

export default SolutionAreasWithText;
