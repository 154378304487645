// button.component.js
import React from 'react';
import { useSlowFade } from 'React/custom_hooks';
import './Table.scss';

import { Link, Lightbox, Wysiwyg, TagTitle } from 'React/components';

const TableDouble = ({ title, cta, className, text, subModules, tableMargin, anchor }) => {
    let moduleRef = React.createRef();
    useSlowFade(moduleRef);

    const classReduced = tableMargin === 'reducido' ? 'reduced-spacing'
        : '';

    return (
        <div ref={moduleRef} className={`module grid module-table table-double ${classReduced} ${className}`}>
            <div className="grid-container">
                {title && <TagTitle style="slow--y" title={title} />}
                <div className="table-wrapper">
                    <span className="custom-anchor" id={anchor} />
                    {subModules.map((table, idx) => {
                        const { isCargos, text, bulletsTitle, bullets, headerDownloads, singleDownload, tagTitle, tableType, cta,
                            className = '', logo, subModules, headers, totals, footerDownload } = table;

                        const tableHeaders = headers.map((header, idx) => (
                            <th className="taglabel" key={`header-${idx}`}>
                                {header}
                            </th>
                        ));

                        return (
                            <div className={`table-container${idx > 0 ? ' no-margin' : ''} slow--y`} key={`table-${idx}`}>
                                <table>
                                    <thead>
                                        <tr>
                                            {tableHeaders}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {subModules.map(({ subModules }, idx) => {
                                            return (
                                                <tr key={`row-${idx}`}>
                                                    {subModules.map((cell, idx) => {
                                                        const { _documentType, text, className, download, position, cta, positionCta, isLightbox } = cell;
                                                        return (
                                                            <td className={`body--s ${className}`} key={`cell-${idx}`}>
                                                                {text && <div dangerouslySetInnerHTML={{ __html: text }}></div>}
                                                                {download && !download.umbracoFile && <Link type="download" label={download.recordNumber ? download.recordNumber : download.title} path={download.file} />}
                                                                {download && download.umbracoFile && <Link type="download" label={download.name} path={download.umbracoFile} bytes={download.umbracoBytes} extension={download.umbracoExtension} />}
                                                                {positionCta && <Link type="arrow" className="cargo" label={positionCta.caption} path={positionCta.link} />}
                                                                {cta && <Link label={cta.caption} path={cta.link} type={isLightbox ? 'lightbox' : cta.type} />}
                                                                {position && <p className="body--s">{position}</p>}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                    {totals && totals.length > 0 && (
                                        <tfoot>
                                            <tr>
                                                <td className="body--l">Total</td>
                                                {totals.map((total, idx) => {
                                                    return (
                                                        <td key={`total-${idx}`} className="body--l">{total}</td>
                                                    );
                                                })}
                                            </tr>
                                        </tfoot>
                                    )}
                                </table>
                            </div>
                        );
                    })}
                </div>
                {text && (
                    <div className="text-container slow--y">
                        <div className="body--s" dangerouslySetInnerHTML={{ __html: text }}></div>
                    </div>
                )}
                {cta && <div className="mt-4 slow--y"><Link label={cta.caption} path={cta.link} type={cta.type} /></div>}
            </div>
        </div>
    );
};

export default TableDouble;
