import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, ModuleNews, PaginatorSimple, Lightbox, PopUpLegalComponent, PopUpLegalComponentInvestor } from 'React/components';
import { rndKeyGen } from 'React/helpers';

const RelevantFactsList = ({ title, type, hideCombo, culture, lblNoNews }) => {
    var today = new Date();
    var actualYear = today.getFullYear().toString();
    const [newsListState, setNewsListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);
    const [yearFilterState, setYearFilterState] = useState(type === 'OTHERS' ? '2020' : actualYear);
    const [actualPagesList, setActualPagesList] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [yearsComboState, setYearsComboState] = useState([]);

    const [visibleLegalLightboxState, setVisibleLegalLightboxState] = useState(false);
    const [visibleLegalLightboxInvestorState, setVisibleLegalLightboxInvestorState] = useState(false);
    const [fileState, setFileState] = useState('');

    const handleLegalLightboxClick = (status, file, type) => {
        setFileState(file);
        if (type && type.includes("Popup-energia")) {
            setVisibleLegalLightboxState(status);
        }
        else if (type && type.includes("Popup-quality-investor")) {
            setVisibleLegalLightboxInvestorState(status);
        }
        else {
            setVisibleLegalLightboxState(status);
        }
    //    switch (type) {
    //        case "Popup-energia":
    //            setVisibleLegalLightboxState(status);
    //            break;
    //        case "Popup-quality-investor":
    //            setVisibleLegalLightboxInvestorState(status);
    //            break;
    //        default:
    //            setVisibleLegalLightboxState(status);
    //            break;
    //    }
    };

    const handleLegalLightboxInvestorClick = (status, file) => {
        setFileState(file);
        setVisibleLegalLightboxInvestorState(status);
    };

    const itemPerPage = 9;

    const getRelevantFactsList = (culture, year) => {
        var relevantFactsList = [];

        if (!year && yearsComboState.length > 0) year = yearsComboState[0];

        var url = `/api/CmsController/GetRelevantFacts?language=${culture}&filter=${type}&year=${year}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {
                    relevantFactsList = JSON.parse(xhrPages.responseText);

                    setActualPagesList(relevantFactsList.pdfList);
                    setYearsComboState(relevantFactsList.yearsList);
                    if (yearsComboState.length === 0 && relevantFactsList.yearsList && relevantFactsList.yearsList.length > 0) setYearFilterState(relevantFactsList.yearsList[0]);

                    var pagesList = [];
                    var listSize = Math.ceil(relevantFactsList.pdfList.length / itemPerPage);

                    for (var i = 0; i !== listSize; i++) {
                        var page = { num: i + 1 };
                        pagesList.push(page);
                    }
                    var downloadsList = paginate(relevantFactsList.pdfList, itemPerPage, actualPageState);

                    if (downloadsList.length > 0 && downloadsList.length < itemPerPage) {
                        var ej1 = {
                            title: "",
                            file: "",
                            date: "",
                            thumbnail: "",
                            recordNumber: ""
                        };
                        var size = itemPerPage - downloadsList.length;
                        for (var j = 0; j < size; j++) {
                            downloadsList.push(ej1);
                        }
                    }

                    setNewsListState(downloadsList);
                    setPagesListState(pagesList);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);
    }

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const changePage = (page) => {
        setActualPageState(page);

        var downloadsList = paginate(actualPagesList, itemPerPage, page);

        if (downloadsList.length < itemPerPage) {
            var ej1 = {
                title: "",
                file: "",
                date: "",
                thumbnail: "",
                recordNumber: ""
            };
            var size = itemPerPage - downloadsList.length;
            for (var j = 0; j < size; j++) {
                downloadsList.push(ej1);
            }
        }

        setNewsListState(downloadsList);
    };

    const nextPage = () => {
        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);
            var downloadsList = paginate(actualPagesList, itemPerPage, actualPageState + 1);

            if (downloadsList.length < itemPerPage) {
                var ej1 = {
                    title: "",
                    file: "",
                    date: "",
                    thumbnail: "",
                    recordNumber: ""
                };
                var size = itemPerPage - downloadsList.length;
                for (var j = 0; j < size; j++) {
                    downloadsList.push(ej1);
                }
            }

            setNewsListState(downloadsList);
        }
    };

    const prevPage = () => {
        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);
            var downloadsList = paginate(actualPagesList, itemPerPage, actualPageState - 1);

            if (downloadsList.length < itemPerPage) {
                var ej1 = {
                    title: "",
                    file: "",
                    date: "",
                    thumbnail: "",
                    recordNumber: ""
                };
                var size = itemPerPage - downloadsList.length;
                for (var j = 0; j < size; j++) {
                    downloadsList.push(ej1);
                }
            }

            setNewsListState(downloadsList);
        }
    };

    const setFilter = (event) => {
        setYearFilterState(event.target.value);
        setActualPageState(1);

        getRelevantFactsList(culture, event.target.value);
    };

    useEffect(() => {
        getRelevantFactsList(culture, yearFilterState);
    }, [setPagesListState]);
    
    return (
        <div className='pagination-list-container'>
            <BuscadorFiltros type='noticias' yearFilter={setFilter} title={`${title} (${actualPagesList.length})`} yearsCombo={yearsComboState} yearSelected={yearFilterState} hideCombo={hideCombo} culture={culture} />
            {newsListState.length == 0 && <div className="module grid"><div className="grid-container body--m slow--y">{lblNoNews}</div></div>}
            {newsListState.length > 0 && <ModuleNews type='hechos' content={newsListState} culture={culture} handleLegalLightboxClick={handleLegalLightboxClick} />}
            {pagesListState.length > 1 &&
                <PaginatorSimple pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} />
            }
            <Lightbox
                key={rndKeyGen()}
                isVisible={visibleLegalLightboxState}
                hideCloseButton
                closeMethod={() => setVisibleLegalLightboxState(null)}
            >
                <PopUpLegalComponent setVisibleLightbox={handleLegalLightboxClick} file={fileState} culture={culture} />
            </Lightbox>
            <Lightbox
                key={rndKeyGen()}
                isVisible={visibleLegalLightboxInvestorState}
                hideCloseButton
                closeMethod={() => setVisibleLegalLightboxInvestorState(null)}
            >
                <PopUpLegalComponentInvestor setVisibleLightbox={handleLegalLightboxClick} file={fileState} culture={culture} />
            </Lightbox>
        </div>
    );
}

export default RelevantFactsList;