import React, { useEffect } from 'react';
import { Link } from 'React/components';
import { TagTitle } from 'React/components';
import IconsMap from './_iconsMap';
import { useSlowFade } from 'React/custom_hooks';

import './styles.scss';

const DistribuidorDestacado = ({
    href,
    logo,
    title,
    text,
    textHtml,
    image,
    ods,
    download,
    solutions,
    tagTitle,
    anchor
}) => {
    let moduleRef = React.createRef();

    useSlowFade(moduleRef);

    if (typeof window !== 'undefined') {
        useEffect(() => {
            function callback(entries, observer) {
                entries.forEach((el) => {
                    if (el.isIntersecting == true) {
                        el.target.classList.add('is-visible');
                    }
                });
            }

            // Instancing a new IntersectionObserver
            const observer = new IntersectionObserver(callback);

            // Adding a target to be observed
            observer.observe(moduleRef.current);
        });
    }

    return (
        <section ref={moduleRef} className={`module grid module-distribuidor-destacado grid-flow--left single`}>
            <div className="grid-container--left">
                <div className="image grid-container--left" style={{ backgroundImage: `url(${image ? image.url : ''})` }}>
                    {ods && <img src={ods.url} alt={ods.alt ? ods.alt : ''} title={ods.title} className="ods" />}
                    {logo && <img src={logo.url} alt={logo.alt ? logo.alt : ''} title={logo.title} className="logo" />}
                </div>
            </div>
            <div className={`grid-container--right`}>
                {tagTitle ? <TagTitle style="slow--y" title={tagTitle} /> : null}

                {title ? <h2 className="title--l slow--y">{title}</h2> : null}
                <span className="custom-anchor" id={anchor} />
                {textHtml ? (
                    <div className="mt-2 text-html">
                        <div className="body--m slow--y" dangerouslySetInnerHTML={{ __html: textHtml }}></div>
                    </div>
                ): null }
                {text && !textHtml ? (
                    <div className="mt-2">
                        <p className="body--m slow--y">{text}</p>
                    </div>
                ) : null}
                
                {solutions && (
                    <div className="soluciones-destacado slow--y">
                        <ul className="grid-container">
                            {solutions.map((solution, idx) => {
                                const { icon, menuText, _contentPath } = solution;

                                return (
                                    <li className="title--xs"><a href={_contentPath}>{IconsMap[icon]}{menuText}</a></li>
                                );
                            })}
                        </ul>
                    </div>
                )}

                {download && (
                    <div className="mt-3 slow--y">
                        <Link type="download" path={download.umbracoFile ? download.umbracoFile : download.file} label={download.name ? download.name : download.title} target={'_blank'} bytes={download.umbracoBytes} extension={download.umbracoExtension} />
                    </div>
                )}

                {href && (
                    <div className="mt-3 slow--y">
                        <Link type={href.type} path={href.link} target={href.target} label={href.caption} module="distribuidor" title={title} />
                    </div>
                )}
            </div>
        </section>
    );
}

export default DistribuidorDestacado;
