import React, { useEffect } from 'react';
import { useObserver } from 'React/custom_hooks';
//import { gsap } from 'gsap';

function useFade(containerRef) {
    if (typeof window !== 'undefined') {
        const [observer, setElements, entries] = useObserver({
            root: null,
            threshold: 0.25,
        });

        const tl = gsap.timeline({ delay: 0, repeat: 0 });

        useEffect(() => {
            if (containerRef.current !== null) {
                let elements = containerRef.current.querySelectorAll('.slow');
                gsap.set(elements, { opacity: 0 });
                setElements(elements);
            }
        }, [setElements]);

        useEffect(() => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    let lazyItem = entry.target;
                    tl.to(lazyItem, 1, { opacity: 1 }, 0.2);
                    observer.unobserve(lazyItem);
                }
            });
        }, [entries, observer, gsap]);
    }
}

export default useFade;
