const useTranslate = (data) => {
  const nameTranslations = {
    AGUA: 'WATER',
    FINANCIERAS: 'FINANCIAL',
    ENERGÍA: 'ENERGY',
    INMOBILIARIA: 'REAL ESTATE',
    TRANSPORTE: 'TRANSPORT',
    CIUDADES: 'CITIES',
    SOCIALES: 'SOCIAL',
  };

  const translateNames = (data) => {
    return data.map((item) => {
      const translatedName = nameTranslations[item.name] || item.name;
      return {
        ...item,
        name: translatedName,
      };
    });
  };

  const translatedData = translateNames(data);

  return translatedData;
};

export default useTranslate;
