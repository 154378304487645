import React from 'react';

import { Link, TagTitle } from 'React/components';

import 'Styles/_texts.scss';
import './style.scss';

const WysiwygEmpleo = ({ description }) => {
    return (
        <div className="module module-wysiwyg">
            <div className="grid">
                <div className="text-container" dangerouslySetInnerHTML={{ __html: description }} />
            </div>
        </div>
    );
};

export default WysiwygEmpleo;
