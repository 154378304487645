import React from 'react';

import { Link } from 'React/components';

import './Styles.scss';


const websAcciona = ({ subModules }) => {
    return (
        <div className="module-webs module grid">
            {subModules && subModules.length > 0 &&
                <div className="grid-container">
                    {subModules.map((item, idx) => {
                        const { title, webs } = item;
                        return (
                            <div className="item" key={`webs-section-${idx}`}>
                                <h2 className="title--s">{title}</h2>
                                {webs && webs.length &&
                                    <nav>
                                        {webs.map((web, idx) => {
                                            return (
                                                <Link type="External" label={web.caption} path={web.link} target={web.target} />
                                            );
                                        })}
                                    </nav>
                                }
                            </div>
                        );
                    })}
                </div>
            }
        </div>
    );
};

export default websAcciona;