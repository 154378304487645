import React from 'react';
import SolutionAreasWithText from '../Table/SolutionsTable/SolutionAreasWithText/SolutionAreasWithText';
import SolutionAreas from '../Table/SolutionsTable/SolutionArea/SolutionAreas';
import SolutionAreasHighlights from '../Table/SolutionsTable/SolutionAreasHighlights/SolutionAreasHighlights';
import DefaultTable from '../Table/DefaultTable/DefaultTable';
import { useCulture } from '../../custom_hooks';

const Table = (props) => {
  const culture = useCulture();
  const tableType = props.tableType;

  let tableComponent;

  switch (tableType) {
    case 'SolutionAreas':
      tableComponent = <SolutionAreas {...props} culture={culture} />;
      break;
    case 'SolutionAreasHighlights':
      tableComponent = <SolutionAreasHighlights {...props} culture={culture} />;
      break;
    case 'SolutionAreasWithText':
      tableComponent = <SolutionAreasWithText {...props} culture={culture} />;
      break;
    default:
      tableComponent = <DefaultTable {...props} culture={culture} />;
      break;
  }

  return <>{tableComponent}</>;
};

export default Table;
