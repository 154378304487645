// button.component.js
import React from 'react';

import './Button.scss';

//const Button = ({ children, label, className, size = 'button--md', type = 'button', style_type = 'base', onClick, disabled = false}) => (
//    <button onClick={onClick} type={type} className={`button taglabel ${size} type__${style_type} ${className}`} disabled={disabled} >
//        {children}
//        {label}
//    </button>
//);

const Button = ({ children, label, className, size = 'button--md', type = 'button', style_type = 'base', onClick, disabled = false, dataMenuClickPath, dataMenuClickGroup, dataMenuClickTarget }) => (
    <button onClick={onClick} type={type} className={`button taglabel ${size} type__${style_type} ${className}`} disabled={disabled} data-menu-click-group={dataMenuClickGroup} data-menu-click-target={dataMenuClickTarget} data-menu-click-path={dataMenuClickPath}  >
        {children}
        {label}
    </button>
);

export default Button;
