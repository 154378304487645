import React, { useState } from 'react';

import { Link, Lightbox, ContactFormGreenEnergy } from 'React/components';
import { ReactComponent as IconCheck } from 'Icons/valid-16.svg';
import './styles.scss';

const ContactForm = ({ title, description, translations }) => {
    const [visibleLightbox, setVisibleLightbox] = useState(false);

    const handleLightboxClick = () => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };

    return (
        <>
            <span onClick={() => handleLightboxClick()}><Link type="lightbox" label="contactar" /></span>
            <Lightbox
                key={rndKeyGen()}
                isVisible={visibleLightbox}
                closeMethod={() => setVisibleLightbox(null)}
            >
                <ContactFormGreenEnergy translations={translations} />
            </Lightbox>
        </>
    );

};

export default ContactForm;
