import React, { useState } from 'react';

import './SearchForm.scss';
import { ReactComponent as SearchLense } from 'Icons/searchlense.svg';

const SearchForm = ({ placeholder, afterSubmitMethod }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const handleSubmit = (event) => {
        event.preventDefault();
        // handle submited data

        afterSubmitMethod();
        setSearchTerm('');
    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <form className="form search-form" onSubmit={handleSubmit}>
            <input
                type="text"
                name="search-term"
                value={searchTerm}
                onChange={handleInputChange}
                id="search-term"
                placeholder={placeholder}
            />
            <input type="submit">
                <SearchLense />
            </input>
        </form>
    );
};

export default SearchForm;
