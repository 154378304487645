import React from 'react';

import { ReactComponent as Energy } from 'Icons/soluciones/energia.svg';
import { ReactComponent as Ciudades } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as Movilidad } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as Agua } from 'Icons/soluciones/agua.svg';
import { ReactComponent as Social } from 'Icons/soluciones/social.svg';
import { ReactComponent as Capital } from 'Icons/soluciones/capital.svg';
import { ReactComponent as Inmobiliaria } from 'Icons/soluciones/inmobiliaria.svg';

const iconsMap = {
    Energia: <Energy width="18" height="18" viewBox="0 0 24 24" />,
    Transporte: <Movilidad width="18" height="18" viewBox="0 0 24 24" />,
    Agua: <Agua width="18" height="18" viewBox="0 0 24 24" />,
    Social: <Social width="18" height="18" viewBox="0 0 24 24" />,
    Ciudades: <Ciudades width="18" height="18" viewBox="0 0 24 24" />,
    Inmobiliaria: <Inmobiliaria width="18" height="18" viewBox="0 0 24 24" />,
    Capital: <Capital width="18" height="18" viewBox="0 0 24 24" />,
    EnergiaDefault: '/static/images/project_default/proyectos_930x600_energia.svg',
    CiudadesDefault: '/static/images/project_default/proyectos_930x600_ciudades.svg',
    TransporteDefault: '/static/images/project_default/proyectos_930x600_transportes.svg',
    AguaDefault: '/static/images/project_default/proyectos_930x600_agua.svg',
    SocialDefault: '/static/images/project_default/proyectos_930x600_social.svg',
    CapitalDefault: '/static/images/project_default/proyectos_930x600_financieras.svg',
    InmobiliariaDefault: '/static/images/project_default/proyectos_930x600_inmobiliaria.svg'
};

const iconsMapLarge = {
    Energia: <Energy width="24" height="24" viewBox="0 0 24 24" />,
    Transporte: <Movilidad width="24" height="24" viewBox="0 0 24 24" />,
    Agua: <Agua width="24" height="24" viewBox="0 0 24 24" />,
    Social: <Social width="24" height="24" viewBox="0 0 24 24" />,
    Ciudades: <Ciudades width="24" height="24" viewBox="0 0 24 24" />,
    Inmobiliaria: <Inmobiliaria width="24" height="24" viewBox="0 0 24 24" />,
    Capital: <Capital width="24" height="24" viewBox="0 0 24 24" />,
};

export { iconsMap, iconsMapLarge };
