import React, { useRef } from 'react';

import { Link } from 'React/components';
import { useStickyHeader } from 'React/custom_hooks';

import './HeaderLanding.scss';
import LogoFull from 'Images/logos/logo_full.svg';
import LogoCampus from 'Images/logos/LogoCampus_acciona.svg';
import LogoEnePol from 'Images/logos/logo_full_ae.svg';


const HeaderLandingOwnDomain = ({ stickyHeader = true, logo, hrefLogo, homeLink, noSlogan, isOwnLanguage, changeLanguageText, changeLanguageLink, cultureInfo, contentPath, logoAltText }) => {
    const headerRef = useRef();

    // STICKY HEADER ON SCROLL UP
    useStickyHeader(headerRef, stickyHeader);

    const isCampus = contentPath.includes("campus");

    const stepOne = contentPath.includes("acciona-energia-poland")
                    || contentPath.includes("certificados-de-ahorro-energetico")
                    || contentPath.includes("planta-biomasa-logrosan")
                    || contentPath.includes("parque-eolico-san-roman")
                    || contentPath.includes("parque-eolico-palmas-altas")
                    || contentPath.includes("planta-fotovoltaica-bolarque")
                    || contentPath.includes("parque-eolico-celada-fusion")
                    || contentPath.includes("planta-fotovoltaica-extremadura")
                    || contentPath.includes("plantas-fotovoltaicas-escepar-y-peralejo")
                    || contentPath.includes("parque-eolico-pedregales")
                    || contentPath.includes("red-tailed-hawk-solar-farm")
                    || contentPath.includes("madison-county-solar-farm")
                    || contentPath.includes("jelinak-wind-farm")
                    || contentPath.includes("union-solar-farm")
                    || contentPath.includes("mason-solar-farm")
                    || contentPath.includes("fleming-solar-farm")
                    || contentPath.includes("krobia-poniec-wind-farm")
                    || contentPath.includes("vjetroelektrana-jelinak")
                    || contentPath.includes("dymerkapv")
                    || contentPath.includes("krobia-i-poniec-ii-wind-farms")
                    || contentPath.includes("palmas-altas-wind-farm")
                    || contentPath.includes("san-roman-wind-farm")
                    || contentPath.includes("fort-bend-solar-farm")
                    || contentPath.includes("high-point-solar-farm")
                    || contentPath.includes("will-county-solar-farm")
                    || contentPath.includes("hudzovkapv")
                    || contentPath.includes("artsyzpv");

    const stepTwo = !contentPath.includes("acciona-energia-poland")
        && !contentPath.includes("certificados-de-ahorro-energetico")
        && !contentPath.includes("planta-biomasa-logrosan")
        && !contentPath.includes("parque-eolico-san-roman")
        && !contentPath.includes("parque-eolico-palmas-altas")
        && !contentPath.includes("planta-fotovoltaica-bolarque")
        && !contentPath.includes("parque-eolico-celada-fusion")
        && !contentPath.includes("planta-fotovoltaica-extremadura")
        && !contentPath.includes("plantas-fotovoltaicas-escepar-y-peralejo")
        && !contentPath.includes("parque-eolico-pedregales")
        && !contentPath.includes("red-tailed-hawk-solar-farm")
        && !contentPath.includes("madison-county-solar-farm")
        && !contentPath.includes("jelinak-wind-farm")
        && !contentPath.includes("union-solar-farm")
        && !contentPath.includes("mason-solar-farm")
        && !contentPath.includes("fleming-solar-farm")
        && !contentPath.includes("krobia-poniec-wind-farm")
        && !contentPath.includes("vjetroelektrana-jelinak")
        && !contentPath.includes("dymerkapv")
        && !contentPath.includes("krobia-i-poniec-ii-wind-farms")
        && !contentPath.includes("palmas-altas-wind-farm")
        && !contentPath.includes("san-roman-wind-farm")
        && !contentPath.includes("fort-bend-solar-farm")
        && !contentPath.includes("high-point-solar-farm")
        && !contentPath.includes("will-county-solar-farm")
        && !contentPath.includes("hudzovkapv")
        && !contentPath.includes("artsyzpv");

    let link = isCampus ? "#" : stepOne ? "https://www.acciona-energia.com" : "https://www.acciona.com";
    link = !isCampus && contentPath.startsWith("/es/") ? link + "/es/" : link;

    let altText = "";
    if (!isCampus) {
        if (stepOne){
            altText = "Logo ACCIONA Energia"; 
        }
        if (stepTwo) {
            altText = "Logo ACCIONA Energy";
        }
    }

    return (
        <>
            {!logo && stepOne
                && (
                <header className={`header-landing${isOwnLanguage ? " header-landing__lang" : ""}`} ref={headerRef} id="header">
                    <Link className="logo-home-link" path={link}>
                            <img className="logo" src={LogoEnePol} alt={altText} title={altText} />
                        </Link>
                        {isOwnLanguage && (
                            <div className="header-landing__language">
                                <div className="landing-own-domain__lang__label">

                                    {cultureInfo === "es" ?
                                        <span className="text__bold text__not-link">{"ES"}</span>
                                        :
                                        <a href={changeLanguageLink}>
                                            <span className="text__bold">{"ES"}</span>
                                        </a>
                                    }

                                    <span className="landing-own-domain__lang__label__vertical">{"|"}</span>

                                    {cultureInfo === "en" ?
                                        <span className="text__bold text__not-link">{"EN"}</span>
                                        :
                                        <a href={changeLanguageLink}>
                                            <span className="text__bold">{"EN"}</span>
                                        </a>
                                    }
                                </div>
                            </div>
                        )
                        }


                    </header>
                )}

            {!logo && isCampus && stepTwo
                && (
                    <header className={`header-landing${isOwnLanguage ? " header-landing__lang" : ""}`} ref={headerRef} id="header">
                        <Link path={link} className="logo-home-link">
                            <img className="logo" src={LogoCampus} alt="Logo Acciona" title="Logo Acciona" />
                        </Link>

                        {isOwnLanguage && (
                            <div className="header-landing__language">
                                <div className="landing-own-domain__lang__label">

                                    {cultureInfo === "es" ?
                                        <span className="text__bold text__not-link">{"ES"}</span>
                                        :
                                        <a href={changeLanguageLink}>
                                            <span className="text__bold">{"ES"}</span>
                                        </a>
                                    }

                                    <span className="landing-own-domain__lang__label__vertical">{"|"}</span>

                                    {cultureInfo === "en" ?
                                        <span className="text__bold text__not-link">{"EN"}</span>
                                        :
                                        <a href={changeLanguageLink}>
                                            <span className="text__bold">{"EN"}</span>
                                        </a>
                                    }
                                </div>
                            </div>
                        )
                        }


                    </header>
                )}
            {!logo && !isCampus && stepTwo
                && (
                    <header className={`header-landing${isOwnLanguage ? " header-landing__lang" : ""}`} ref={headerRef} id="header">
                        <Link path={link} className="logo-home-link">
                            <img className={`logo${noSlogan ? ' logo-sainz' : ''}`} src={noSlogan ? '/static/images/logos/acciona-sainz-xe-team.jpg' : LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                        </Link>

                        {isOwnLanguage && (
                            <div className="header-landing__language">
                                <div className="landing-own-domain__lang__label">

                                    {cultureInfo === "es" ?
                                        <span className="text__bold text__not-link">{"ES"}</span>
                                        :
                                        <a href={changeLanguageLink}>
                                            <span className="text__bold">{"ES"}</span>
                                        </a>
                                    }

                                    <span className="landing-own-domain__lang__label__vertical">{"|"}</span>

                                    {cultureInfo === "en" ?
                                        <span className="text__bold text__not-link">{"EN"}</span>
                                        :
                                        <a href={changeLanguageLink}>
                                            <span className="text__bold">{"EN"}</span>
                                        </a>
                                    }
                                </div>
                            </div>
                        )
                        }


                    </header>
                )}
            {logo
                && stepOne
                && !isCampus && (
                    <header className={`page-header grid header-landing header-landing--logo${isOwnLanguage ? " header-landing-logo__lang" : " header-landing--logo"}`} ref={headerRef} id="header">
                        <Link path={link} className="logo-home-link">
                            <img className="logo" src={LogoEnePol} alt={altText} title={altText} />
                        </Link>
                        <Link path={link} className="logo-home-right">
                            <img
                                className="logo"
                                src={logo.url}
                                alt={logo.name}
                                title={logo.title}
                            />
                        </Link>
                        {isOwnLanguage && (
                            <div className="header-landing__language-with-logo">
                                <div className="landing-own-domain__lang__label">
                                    {cultureInfo === "es" ?
                                        <a href={changeLanguageLink}>
                                            <span className="text__not-link">{"EN"}</span>
                                        </a> :
                                        <span className="text__bold">{"EN"}</span>}

                                    <span className="landing-own-domain__lang__label__vertical">{"|"}</span>
                                    {cultureInfo === "en" ?
                                        <a href={changeLanguageLink}>
                                            <span className="text__not-link">{"ES"}</span>
                                        </a> :
                                        <span className="text__bold">{"ES"}</span>}
                                </div>
                            </div>
                        )
                        }

                    </header>
                )}
            {logo && isCampus && stepTwo
                && (
                    <header className={`page-header grid header-landing header-landing--logo${isOwnLanguage ? " header-landing-logo__lang" : " header-landing--logo"}`} ref={headerRef} id="header">
                        <Link path={link} className="logo-home-link">
                            <img className="logo" src={LogoCampus} alt="Logo Acciona" title="Logo Acciona" />
                        </Link>
                        <Link path={link} className="logo-home-right">
                            <img
                                className="logo"
                                src={logo.url}
                                alt={logo.name}
                                title={logo.title}
                            />
                        </Link>
                        {isOwnLanguage && (
                            <div className="header-landing__language-with-logo">
                                <div className="landing-own-domain__lang__label">
                                    {cultureInfo === "es" ?
                                        <a href={changeLanguageLink}>
                                            <span className="text__not-link">{"EN"}</span>
                                        </a> :
                                        <span className="text__bold">{"EN"}</span>}

                                    <span className="landing-own-domain__lang__label__vertical">{"|"}</span>
                                    {cultureInfo === "en" ?
                                        <a href={changeLanguageLink}>
                                            <span className="text__not-link">{"ES"}</span>
                                        </a> :
                                        <span className="text__bold">{"ES"}</span>}
                                </div>
                            </div>
                        )
                        }

                    </header>
                )}
            {logo && !isCampus && stepTwo
                && (
                    <header className={`page-header grid header-landing header-landing--logo${isOwnLanguage ? " header-landing-logo__lang" : " header-landing--logo"}`} ref={headerRef} id="header">
                    <Link path={link} className="logo-home-link">
                            <img className="logo" src={noSlogan ? Logo : LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                        </Link>
                        <Link path={link} className="logo-home-right">
                            <img
                                className="logo"
                                src={logo.url}
                                alt={logo.name}
                                title={logo.title}
                            />
                        </Link>
                        {isOwnLanguage && (
                            <div className="header-landing__language-with-logo">
                                <div className="landing-own-domain__lang__label">
                                    {cultureInfo === "es" ?
                                        <a href={changeLanguageLink}>
                                            <span className="text__not-link">{"EN"}</span>
                                        </a> :
                                        <span className="text__bold">{"EN"}</span>}

                                    <span className="landing-own-domain__lang__label__vertical">{"|"}</span>
                                    {cultureInfo === "en" ?
                                        <a href={changeLanguageLink}>
                                            <span className="text__not-link">{"ES"}</span>
                                        </a> :
                                        <span className="text__bold">{"ES"}</span>}
                                </div>
                            </div>
                        )
                        }

                    </header>
                )}
        </>
    );
};

export default HeaderLandingOwnDomain;
