import { useEffect } from 'react';
import Hammer from '@egjs/hammerjs';

function useSwipeDownToClose(swipeTarget, closeMethod) {
  useEffect(() => {
    const headerSwipeManager = new Hammer.Manager(swipeTarget.current);
    const SliderSwipe = new Hammer.Swipe().set({
      direction: Hammer.DIRECTION_VERTICAL,
    });

    headerSwipeManager.add(SliderSwipe);

    const headerSwipeHandler = (event) => {
      const direction = event.offsetDirection;
      if (direction === 16) {
        closeMethod(null);
      }
    };

    headerSwipeManager.on('swipedown', headerSwipeHandler, []);
  });
}

export default useSwipeDownToClose;
