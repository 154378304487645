import React, { useState } from 'react';
import { CheckboxGroup, Checkbox, RadioButton, TextInput, SelectInput, Button, Lightbox, Wysiwyg } from 'React/components';
import { ReactComponent as CrossBig } from 'Icons/cross-32.svg';
import './styles.scss';
import { isForbiddenCountryEnergy } from 'React/helpers';
import { countryListES, countryList } from './CountriesList';
import {
    description1ES, description1EN, countryResidenceES, countryResidenceEN, physicallyPresentES, physicallyPresentEN,
    description2ES, description2EN, physicallyResidentRadioButtonES, physicallyResidentRadioButtonEN, iHaveReadES,
    iHaveReadEN, forbiddenES, forbiddenEN, titleES, titleEN
} from './Translations';

// firstStep prop is used for internal validation only
const PopUpLegal = ({ firstStep = 'initial', culture }) => {
    const [formStep, setFormStep] = useState(firstStep);

    const [visibleLightbox, setVisibleLightbox] = useState(true);

    const [countryResidenceError, setCountryResidenceError] = useState('');
    const [actualCountryResidenceError, setActualCountryResidenceError] = useState('');
    const [residentError, setResidentError] = useState('');
    const [legalPermissionError, setLegalPermissionError] = useState('');

    const backToHome = () => {
        culture === 'es' ? window.location = "/es/" : window.location = "/";
    };

    const handleSubmit1 = (e) => {
        e.preventDefault();

        var validationErrors = false;

        var countryResidence = e.target.countryResidence.value;
        var actualCountryResidence = e.target.actualCountryResidence.value;

        if (countryResidence) {
            setCountryResidenceError("");
        }
        else {
            validationErrors = true;
            setCountryResidenceError(culture === 'es' ? "Debe seleccionar un país" : " You must select a country");
        }

        if (actualCountryResidence) {
            setActualCountryResidenceError("");
        }
        else {
            validationErrors = true;
            setActualCountryResidenceError(culture === 'es' ? "Debe seleccionar un país" : " You must select a country");
        }

        if (!validationErrors) {
            if (isForbiddenCountryEnergy(countryResidence) || isForbiddenCountryEnergy(actualCountryResidence)) {
                setFormStep("forbidden");
            }
            else {
                setFormStep("accept");
            }
        }
    };

    const handleSubmit2 = (e) => {
        e.preventDefault();

        var validationErrors = false;

        var resident = e.target.resident.value;
        var legalPermission = e.target.legalPermission.value;

        if (resident) {
            setResidentError("");
        }
        else {
            validationErrors = true;
            setResidentError(culture === 'es' ? "Debe seleccionar una opción" : "You must select an option ");
        }

        if (legalPermission) {
            setLegalPermissionError("");
        }
        else {
            validationErrors = true;
            setLegalPermissionError(culture === 'es' ? "Debe seleccionar una opción" : "You must select an option ");
        }

        if (!validationErrors) {
            if (resident.toLowerCase() === "no" || legalPermission.toLowerCase() === "no") {
                setFormStep("forbidden");
            }
            else {
                setVisibleLightbox(false);
                document.querySelector('.app--layout').dataset.canScroll = true;
            }
        }
    };

    return (
        <Lightbox isVisible={visibleLightbox} hideCloseButton>
            {formStep === 'initial' &&
                <form className="module grid contact_form one-column" onSubmit={handleSubmit1}>
                    <div className="form_header">
                        <legend className="form_title title--l">{culture === 'es' ? titleES : titleEN}</legend>
                        <div className="form_desciption body--m" dangerouslySetInnerHTML={{ __html: culture === 'es' ? description1ES : description1EN }} />
                    </div>
                    <fieldset className="form_inputs">
                        <p className="form_section_title body--l"></p>
                        <SelectInput label={culture === 'es' ? countryResidenceES : countryResidenceEN}
                            name="countryResidence"
                            options={culture === 'es' ? countryListES : countryList}
                            defaultValue={culture === 'es' ? "Selecciona un país" : "Select a country"}
                            errorMsg={countryResidenceError} />
                        <p className="form_section_title body--l"></p>
                        <SelectInput label={culture === 'es' ? physicallyPresentES : physicallyPresentEN}
                            name="actualCountryResidence"
                            options={culture === 'es' ? countryListES : countryList}
                            defaultValue={culture === 'es' ? "Selecciona un país" : "Select a country"}
                            errorMsg={actualCountryResidenceError} />
                        <p className="form_section_title body--l"></p>
                        <div className="form_controls_wrapper">
                            <Button
                                type="submit"
                                className="form_submit"
                                label={culture === 'es' ? "Aceptar" : "Accept"}
                                size="button--lg"
                                style_type="red"
                            />
                            <Button className="form_close" label={culture === 'es' ? "Volver a la HOME" : "Back to HOME"} size="button--lg" onClick={() => backToHome()} />
                        </div>
                    </fieldset>
                </form>
            }
            {formStep === 'accept' &&
                <form className="module grid contact_form one-column" onSubmit={handleSubmit2}>
                    <div className="form_header">
                        <legend className="form_title title--l">{culture === 'es' ? titleES : titleEN}</legend>
                        <div className="form_desciption body--m" dangerouslySetInnerHTML={{ __html: culture === 'es' ? description2ES : description2EN }} />
                    </div>
                    <fieldset className="form_inputs">
                        <RadioButton
                            label={culture === 'es' ? physicallyResidentRadioButtonES : physicallyResidentRadioButtonEN}
                            name="resident"
                            errorMsg={residentError}
                            options={[{ value: 'yes', label: culture === 'es' ? 'sí' : 'yes' }, { value: 'no', label: 'no' }]}
                            required
                        />
                        <RadioButton
                            label={culture === 'es' ? iHaveReadES : iHaveReadEN}
                            name="legalPermission"
                            errorMsg={legalPermissionError}
                            options={[{ value: 'yes', label: culture === 'es' ? 'sí' : 'yes' }, { value: 'no', label: 'no' }]}
                            required
                        />
                        <p className="form_section_title body--l"></p>
                        <div className="form_controls_wrapper">
                            <Button
                                type="submit"
                                className="form_submit"
                                label={culture === 'es' ? "Aceptar" : "Accept"}
                                size="button--lg"
                                style_type="red"
                            />
                            <Button className="form_close" label={culture === 'es' ? "Volver a la HOME" : "Back to HOME"} size="button--lg" onClick={() => backToHome()} />
                        </div>
                    </fieldset>
                </form>
            }
            {formStep === 'forbidden' &&
                <div className="form_feedback">
                    <div className="feedback-msg">
                        <div className="icon-container">
                            <div className="centerIcon"><CrossBig className="validation-fail forbidden" width="48" height="48" /></div>
                        </div>
                        <h3 className="body--l">{culture === 'es' ? titleES : titleEN}</h3>
                        <h4 className="body--m">{culture === 'es' ? forbiddenES : forbiddenEN}</h4>
                        <div className="form_controls_wrapper">
                            <br /><br />
                            <Button className="form_close" label={culture === 'es' ? "Volver a la HOME" : "Back to HOME"} size="button--lg" onClick={() => backToHome()} style_type="red" />
                        </div>
                    </div>
                </div>
            }
        </Lightbox>
    );
};

export default PopUpLegal;
