import React, { useState } from 'react';
import { useSlowFade } from 'React/custom_hooks';

import { rndKeyGen } from 'React/helpers';
import { Link, Lightbox, Wysiwyg } from 'React/components';
import './styles.scss';

const Wells = ({ title, description, cta, subModules, anchor, culture }) => {
    let moduleRef = React.createRef();
    useSlowFade(moduleRef);
    const [visibleLightbox, setVisibleLightbox] = useState(null);

    const goalsArray = subModules;

    const handleGoalClick = (number) => {
        const clickedGoal = goalsArray[number];
        if (clickedGoal && clickedGoal.popUpText !== '') {
            setVisibleLightbox(number);
        }
    };

    if (typeof window !== 'undefined') {
        const goalsImagesUrlPrefix = culture === 'es' ?
            '/static/development_goals_images/Goal_' : '/static/development_goals_images_en/Goal_';

        return (
            <>
                <div ref={moduleRef} id="devGoals" className="module grid development-goals wells">
                    <h2 className="title title--l slow--y">{title}</h2>
                    <div className="description body--m slow--y">

                        <div className="description-paragraph" dangerouslySetInnerHTML={{ __html: description }}></div>

                        {cta && (
                            <p className="slow--y spaced">
                                <Link className="module--cta" type="arrow" path={cta.link} target={cta.target}>
                                    {cta.caption}
                                </Link>
                            </p>
                        )}

                    </div>
                    <span className="custom-anchor" id={anchor} />
                    <div className="wells-wrapper goals-buttons slow--y">
                        {goalsArray.map((goal, idx) => {
                            var currentGoal = goal;
                            // lightbox
                            return (
                                <div className="goal-button" key={idx} id={idx} data-is-active={currentGoal !== undefined}>
                                    <img
                                        data-is-active={currentGoal !== undefined}
                                        onClick={() => handleGoalClick(idx)}
                                        src={currentGoal.image.url}
                                    />
                                    <p onClick={() => handleGoalClick(idx)}>{currentGoal.title}</p>
                                </div>
                            );
                        })}
                    </div>

                </div>
                {goalsArray.map((goal, idx) => {
                    //if (visibleLightbox === idx) {
                    //    return (
                    //        <Lightbox
                    //            key={idx}
                    //            isVisible="true"
                    //            closeMethod={() => setVisibleLightbox(null)}
                    //        >
                    //            <Wysiwyg html={goal.popUpText} links="" />
                    //        </Lightbox>
                    //    );
                    //} else {
                    //    return (
                    //        <Lightbox
                    //            key={idx}
                    //            isVisible="false"
                    //            closeMethod={() => setVisibleLightbox(null)}
                    //        >
                    //            <Wysiwyg html={goal.popUpText} links="" />
                    //        </Lightbox>
                    //    );
                    //}
                    return (
                        <Lightbox
                            key={idx}
                            isVisible={visibleLightbox === idx}
                            closeMethod={() => setVisibleLightbox(null)}
                            wellsCloseButton={true}
                            >
                                <Wysiwyg html={goal.popUpText} links="" />
                            </Lightbox>
                        );
                })}
            </>
        );
    }
    else {
        return (<>
            <div ref={moduleRef} id="devGoals" className="module grid development-goals">
                <h2 className="title title--l slow--y">{title}</h2>
            </div>
        </>);
    }

};

export default Wells;
