import React from 'react';

// this is a wrapper component we use for internal validation of atoms
const GridWrapper = ({ children, style }) => (
  <div className="module grid grid-wrapper" style={style}> 
    { children }
  </div>
);

export default GridWrapper;
