import React from 'react';
import './styles.scss';

import { ReactComponent as IconLeft } from 'Icons/paginatorLeft.svg';
import { ReactComponent as IconRight } from 'Icons/paginatorRight.svg';

const Paginator = ({ pagesList, actualPage, changePage, prevPage, nextPage, actualPagesList }) => {
    const pageLimit = 15;

    if (pagesList && pagesList.length > 0 && actualPagesList.length > 0) {

        return (
            <div className="module grid module-paginator">
                <nav className="slow">
                    {actualPage !== 1 &&
                        <button className="button--left" onClick={() => prevPage()}>
                            <IconLeft />
                        </button>
                    }
                    {!actualPagesList.some(el => el.num === pagesList[0].num) &&
                        <a href={`#${pagesList[0].num}`} className='' onClick={() => changePage(pagesList[0].num)}>{pagesList[0].num}</a>
                    }
                    {!actualPagesList.some(el => el.num === pagesList[0].num) &&
                        <a href="#" className="not-clickable">
                            ...
                    </a>
                    }
                    {actualPagesList.map((page, idx) => {
                        return (
                            <a href={`#${page.num}`} key={`paginator-page-${idx}`} className={actualPage == page.num ? 'is-active' : ''} onClick={() => changePage(page.num)}>{page.num}</a>
                        );
                    })}
                    {!actualPagesList.some(el => el.num === pagesList[pagesList.length - 1].num) &&
                        <a href="#" className="not-clickable">
                            ...
                    </a>
                    }
                    {!actualPagesList.some(el => el.num === pagesList[pagesList.length - 1].num) &&
                        <a href={`#${pagesList[pagesList.length - 1].num}`} className='' onClick={() => changePage(pagesList[pagesList.length - 1].num)}>{pagesList[pagesList.length - 1].num}</a>
                    }
                    {actualPage !== pagesList[pagesList.length - 1].num &&
                        <button className="button--right" onClick={() => nextPage()}>
                            <IconRight />
                        </button>
                    }
                </nav>
            </div>
        );
    }
    else {
        return (<div className="module grid module-paginator"></div>);
    }
};

export default Paginator;
