import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, DescargasItem, PaginatorSimple } from 'React/components';

const DownloadsList = ({ title, subModules, labelReports, labelPresentations, culture }) => {
    const [downloadsListState, setDownloadsListState] = useState([]);
    const [downloadsFilteredListState, setDownloadsFilteredListState] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);
    const [reportFilterState, setReportFilterState] = useState(false);
    const [presentationFilterState, setPresentationFilterState] = useState(false);

    const itemPerPage = 8;

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const changePage = (page) => {
        document.getElementById("header").scrollIntoView();
        setActualPageState(page);

        var downloadsList = paginate(downloadsFilteredListState, itemPerPage, page);
        setDownloadsListState(downloadsList);
    };

    const nextPage = () => {
        if (actualPageState < pagesListState.length) {
            document.getElementById("header").scrollIntoView();
            setActualPageState(actualPageState + 1);
            var downloadsList = paginate(downloadsFilteredListState, itemPerPage, actualPageState + 1);
            setDownloadsListState(downloadsList);
        }
    };

    const prevPage = () => {
        if (actualPageState > 1) {
            document.getElementById("header").scrollIntoView();
            setActualPageState(actualPageState - 1);
            var downloadsList = paginate(downloadsFilteredListState, itemPerPage, actualPageState - 1);
            setDownloadsListState(downloadsList);
        }
    };

    const setFilter = (option) => {

        var filteredList = [];
        var pagesList = [];
        var listSize;
        var page;
        var downloadsList = [];

        if (option === "Informes de resultados" || option === "Results reports") {
            setReportFilterState(!reportFilterState);

            subModules.map((item, idx) => {
                if (item.type.indexOf("Informes") !== -1 && !reportFilterState === true) {
                    filteredList.push(item);
                }
                else if (presentationFilterState && item.type.indexOf("Presentaciones") !== -1) {
                    filteredList.push(item);
                }
                else if (!reportFilterState === false && presentationFilterState === false) {
                    filteredList.push(item);
                }
            });

            listSize = Math.ceil(filteredList.length / itemPerPage);

            for (var i = 0; i !== listSize; i++) {
                page = { num: i + 1 };
                pagesList.push(page);
            }

            setPagesListState(pagesList);
            setDownloadsFilteredListState(filteredList);

            downloadsList = paginate(filteredList, itemPerPage, actualPageState);

            if (downloadsList.length < itemPerPage) {
                var ej1 = {
                    title: "",
                    type: "",
                    downloads: [],
                    lightboxContent: "",
                };
                var size = itemPerPage - downloadsList.length;
                for (var j = 0; j < size; j++) {
                    downloadsList.push(ej1);
                }
            }

            setDownloadsListState(downloadsList);
        }
        if (option === "Presentaciones" || option === "Presentations") {
            setPresentationFilterState(!presentationFilterState);

            subModules.map((item, idx) => {
                if (item.type.indexOf("Presentaciones") !== -1 && !presentationFilterState === true) {
                    filteredList.push(item);
                }
                else if (reportFilterState && item.type.indexOf("Informes") !== -1) {
                    filteredList.push(item);
                }
                else if (reportFilterState === false && !presentationFilterState === false) {
                    filteredList.push(item);
                }
            });

            listSize = Math.ceil(filteredList.length / itemPerPage);

            for (var j = 0; j !== listSize; j++) {
                page = { num: j + 1 };
                pagesList.push(page);
            }

            setPagesListState(pagesList);
            setDownloadsFilteredListState(filteredList);

            downloadsList = paginate(filteredList, itemPerPage, actualPageState);

            if (downloadsList.length < itemPerPage) {
                var ej1 = {
                    title: "",
                    type: "",
                    downloads: [],
                    lightboxContent: "",
                };
                var size = itemPerPage - downloadsList.length;
                for (var j = 0; j < size; j++) {
                    downloadsList.push(ej1);
                }
            }

            setDownloadsListState(downloadsList);
        }
    };

    useEffect(() => {
        setDownloadsFilteredListState(subModules);
        var teamPagesList = [];
        var listSize = Math.ceil(subModules.length / itemPerPage);

        for (var i = 0; i !== listSize; i++) {
            var page = { num: i + 1 };
            teamPagesList.push(page);
        }
        var downloadsList = paginate(subModules, itemPerPage, actualPageState);
        setDownloadsListState(downloadsList);
        setPagesListState(teamPagesList);
    }, [setPagesListState]);

    if (downloadsListState.length > 0) {
        return (
            <div className='pagination-list-container'>
                <BuscadorFiltros title={title} type='informes' searchFilter={setFilter} labelReports={labelReports} labelPresentations={labelPresentations} />
                <DescargasItem list={downloadsListState} culture={culture} />
                {pagesListState.length > 1 &&
                    <PaginatorSimple pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} />
                }
            </div>
        );
    }
    else {
        return (<></>);
    }

}

export default DownloadsList;