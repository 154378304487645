// button.component.js
import React, { useState } from 'react';
import './styles.scss';
import { CheckboxGroup, TagTitle } from 'React/components';
import { ReactComponent as IconSearch } from 'Icons/searchlense.svg';
import { ReactComponent as IconShow } from 'Icons/filteropen-16.svg';
import { ReactComponent as IconHide } from 'Icons/filterclose-16.svg';
import { useFade } from '../../custom_hooks';

const BuscadorFiltrosProyectos = ({ type, title, searchFilter,
    hideCombo, solutionsCombo, areasCombo, countriesCombo,
    getProjectsList, culture, setActiveSolution, activeSolution, setActiveArea, activeArea, searchProjects, activeCountry, setActiveCountry, hideFilters }) => {

    const [filterState, setFilterState] = useState(false);
    const [filterAreaState, setFilterAreaState] = useState('');

    const _btnIcon = () => (filterState === true ? <IconShow /> : <IconHide />);
    const _btnLabelES = () => (filterState === true ? 'Mostrar filtros' : 'Ocultar filtros');
    const _btnLabelEN = () => (filterState === true ? 'Show Filters' : 'Hide Filters');

    let moduleRef = React.createRef();

    useFade(moduleRef);

    if (type === 'proyectos') {
        return (
            <div
                ref={moduleRef}
                className={`grid module module-buscadorfiltros--proyectos module-buscadorfiltros ${
                    filterState === true ? 'hide' : 'show'
                    }`}
            >

                <div className="grid-container">
                    <div className="grid-container__title-filter-btn">
                        <h2 className="title--s mb-2 slow--y">{culture === 'es' ? '¿Qué tipo de proyecto buscas?' : 'What type of project are you looking for?'} </h2>
                        {!hideFilters &&
                            <div className="grid-container">
                                <button id="button-filters" onClick={() => setFilterState((oldState) => !oldState)}>
                                    <span className="taglabel">
                                        <_btnIcon /> {culture === 'es' && <_btnLabelES />}{culture !== 'es' && <_btnLabelEN />}
                                    </span>
                                </button>
                            </div>
                        }
                    </div>
                    <form className="filters-container">
                        <div className="selects-container">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" value={activeSolution} onChange={setActiveSolution}>
                                        <option value="">
                                            {culture === 'es' ? 'Solución' : 'Solution'}
                                        </option>
                                        {solutionsCombo.map((item, idx) => {
                                            return (
                                                <option key={`solutions-combo-${idx}`} value={item.value}>{item.name}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">{culture === 'es' ? 'Selecciona una solución' : 'Select a solution'}</label>
                                </div>
                                {activeSolution &&
                                    <div className="select">
                                        <select name="slct" id="slct" value={activeArea} onChange={setActiveArea}>
                                            <option value="">
                                                {culture === 'es' ? 'Área de actividad' : 'Activity Area'}
                                            </option>
                                            {areasCombo.map((item, idx) => {
                                                return (
                                                    <option key={`areas-combo-${idx}`} value={item.value}>{item.name}</option>
                                                );
                                            })}
                                        </select>
                                        <label className="taglabel">{culture === 'es' ? 'Selecciona un Área de actividad' : 'Select an Activity Area'}</label>
                                    </div>
                                }
                                <div className="select">
                                    <select name="slct" id="slct" value={activeCountry} onChange={setActiveCountry}>
                                        <option value="">
                                            {culture === 'es' ? 'País' : 'Country'}
                                        </option>
                                        {countriesCombo.map((item, idx) => {
                                            return (
                                                <option key={`countries-combo-${idx}`} value={item}>{item}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">{culture === 'es' ? 'Selecciona un País' : 'Select a Country'}</label>
                                </div>
                            </div>
                        </div>
                        {/*<div className="checkbox-filters">
                            <div className="checkbox-filter">
                                <CheckboxGroup label={culture === 'es' ? "Proyectos con" : "Projects with"} check1={culture === 'es' ? "Innovación" : "Innovation"} id1="innovacion" check2={culture === 'es' ? "Sostenibilidad" : "Sustainability"} id2="sostenibilidad" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={activeSolution === 'Energia' ? {} : { display: 'none' }}>
                                <CheckboxGroup label={culture === 'es' ? "Titularidad" : "Ownership"} check1={culture === 'es' ? "En propiedad" : "Owned"} id1="en-propiedad"
                                    check2={culture === 'es' ? "Cliente" : "Client"} id2="cliente" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={activeSolution === 'Energia' ? {} : { display: 'none' }}>
                                <CheckboxGroup label={culture === 'es' ? "Estado" : "State"} check1={culture === 'es' ? "Instalada" : "Installed"} id1="instalada"
                                    check2={culture === 'es' ? "En construcción" : "In construction"} id2="en-construccion" event={searchFilter}
                                    check3={culture === 'es' ? "En operación" : "In operation"} id3="en-operacion" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={(activeSolution === 'Transporte' || activeSolution === 'Agua') ? {} : { display: 'none' }}>
                                <CheckboxGroup label={culture === 'es' ? "Estado" : "State"} check1={culture === 'es' ? "Ejecutado" : "Executed"} id1="ejecutado"
                                    check2={culture === 'es' ? "En construcción" : "In construction"} id2="en-construccion2" event={searchFilter}
                                    check3={culture === 'es' ? "En operación" : "In operation"} id3="en-operacion2" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={(activeSolution === 'Transporte' || activeSolution === 'Agua') ? {} : { display: 'none' }}>
                            </div>
                        </div>*/}
                        <div className="checkbox-filters">
                            <div className="checkbox-filter">
                                <CheckboxGroup label={culture === 'es' ? "Proyectos con" : "Projects with"} check1={culture === 'es' ? "Innovación" : "Innovation"} id1="innovacion" check2={culture === 'es' ? "Sostenibilidad" : "Sustainability"} id2="sostenibilidad" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={activeSolution === 'Energia' ? {} : { display: 'none' }}>
                                <CheckboxGroup label={culture === 'es' ? "Estado" : "State"} check1={culture === 'es' ? "Instalada" : "Installed"} id1="instalada"
                                    check2={culture === 'es' ? "En construcción" : "In construction"} id2="en-construccion" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={(activeSolution === 'Transporte' || activeSolution === 'Agua') ? {} : { display: 'none' }}>
                                <CheckboxGroup label={culture === 'es' ? "Estado" : "State"} check1={culture === 'es' ? "Ejecutado" : "Executed"} id1="ejecutado"
                                    check2={culture === 'es' ? "En construcción" : "In construction"} id2="en-construccion2" event={searchFilter} />
                            </div>
                            <div className="checkbox-filter" style={(activeSolution === 'Transporte' || activeSolution === 'Agua') ? {} : { display: 'none' }}>
                            </div>
                            <div className="checkbox-filter" style={activeSolution === 'Energia' ? {} : { display: 'none' }}>
                                <CheckboxGroup label={culture === 'es' ? "Titularidad" : "Ownership"} check1={culture === 'es' ? "En propiedad" : "Owned"} id1="en-propiedad"
                                    check2={culture === 'es' ? "Cliente" : "Client"} id2="cliente" event={searchFilter} />
                            </div>
                        </div>
                    </form>
                    <button type="button" className="search-btn" onClick={() => searchProjects()}>
                        <IconSearch />{' '}
                    </button>
                </div>
            </div>
        );
    }

    if (type === 'proyectos-simple') {
        return (
            <div
                ref={moduleRef}
                className={`slow grid module module-buscadorfiltros--proyectos module-buscadorfiltros--proyectos-simple module-buscadorfiltros ${
                    filterState === true ? 'hide' : 'show'
                    }`}
            >
                <div className="grid-container">
                    <form className="filters-container ">
                        <div className="selects-container select-proyectos-simple">
                            <div className="select-container">
                                <div className="select">
                                    <select name="slct" id="slct" value={activeSolution} onChange={setActiveSolution}>
                                        <option value="">
                                            {culture === 'es' ? 'Solución' : 'Solution'}
                                        </option>
                                        {solutionsCombo.map((item, idx) => {
                                            return (
                                                <option key={`solutions-combo-${idx}`} value={item.value}>{item.name}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">{culture === 'es' ? 'Solución' : 'Solution'}</label>
                                </div>
                                <div className="select">
                                    <select name="slct" id="slct" value={activeCountry} onChange={setActiveCountry}>
                                        <option value="">
                                            {culture === 'es' ? 'País' : 'Country'}
                                        </option>
                                        {countriesCombo.map((item, idx) => {
                                            return (
                                                <option key={`countries-combo-${idx}`} value={item}>{item}</option>
                                            );
                                        })}
                                    </select>
                                    <label className="taglabel">{culture === 'es' ? 'País' : 'Country'}</label>
                                </div>
                                <div className="checkbox-filters">
                                    <div className="checkbox-filter">
                                        <CheckboxGroup label={culture === 'es' ? "Tipo de proyecto" : "Project Type"} check1={culture === 'es' ? "Innovación" : "Innovation"} check2={culture === 'es' ? "Sostenibilidad" : "Sustainability"} event={searchFilter} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <button type="button" className="search-btn" onClick={() => searchProjects()}>
                        <IconSearch />{' '}
                    </button>
                </div>
            </div>
        );
    }
};

const changeFilterButton = (e) => {
    let textContent = e.currentTarget.textContent;

    if (textContent === 'Mostrar filtros') {
        e.currentTarget.textContent = 'Ocultar filtros';
    }
    if (textContent === 'Ocultar filtros') {
        e.currentTarget.textContent = 'Mostrar filtros';
    }
};

export default BuscadorFiltrosProyectos;
