import React, { useState, useEffect } from 'react';
import { TagTitle, InputRangeSlider, Link } from 'React/components';
import { useSliderSwipeFeatured } from 'React/custom_hooks';
import { ReactComponent as IconArrowRightLong } from 'Icons/ArrowLong.svg';

import IconsMap from './_iconsMap';

import './styles.scss';

const ExternalArticlesSlider = ({ frames, pretitle, labelLinkText, anchor }) => {

    const swipableComponent = React.createRef();
    const [activeFrame, setActiveFrame] = useState(1);

    const prevButton = React.createRef();
    const nextButton = React.createRef();

    const nextHover = () => {
        swipableComponent.current.querySelector('.frame-active .next-button').classList.add('is-animated');
        swipableComponent.current.querySelector('.frame-active .prev-button').classList.remove('is-animated');
    };

    const prevHover = () => {
        swipableComponent.current.querySelector('.frame-active .next-button').classList.remove('is-animated');
        swipableComponent.current.querySelector('.frame-active .prev-button').classList.add('is-animated');
    };

    const removeHover = () => {
        swipableComponent.current.querySelector('.frame-active .next-button').classList.remove('is-animated');
        swipableComponent.current.querySelector('.frame-active .prev-button').classList.remove('is-animated');
    };

    const nextFrame = () => {
        if (activeFrame < frames.length) {
            setActiveFrame(activeFrame + 1);
        }
    };

    const prevFrame = () => {
        if (activeFrame > 1) {
            setActiveFrame(activeFrame - 1);
        }
    };

    useEffect(() => {
        const ref = swipableComponent.current;

        function callback(entries, observer) {
            entries.forEach((el) => {
                if (el.isIntersecting == true) {
                    el.target.classList.add('is-visible');
                }
            });
        }

        // Instancing a new IntersectionObserver
        const observer = new IntersectionObserver(callback);

        // Adding a target to be observed
        observer.observe(ref);
    });

    useSliderSwipeFeatured(swipableComponent, setActiveFrame, frames.length);

    return (
        <section
            data-active-frame={activeFrame}
            ref={swipableComponent}
            className={`module grid module-distribuidor-destacado with-slider grid-flow--left`}
        >
            {frames.length > 1 && (
                <>
                    <button
                        className="ghost-frame-button ghost-frame-button--left"
                        onMouseOver={prevHover}
                        onMouseOut={removeHover}
                        onClick={prevFrame}
                    >
                        Slider anterior
                </button>
                    <button
                        className="ghost-frame-button ghost-frame-button--right"
                        onMouseOver={nextHover}
                        onMouseOut={removeHover}
                        onClick={nextFrame}
                    >
                        Slider siguiente
                </button>
                </>
            )}
            <span className="custom-anchor" id={anchor} />
            {frames.map((frame, idx) => {

                const { title, subTitle, url, img } = frame;

                return (

                    <div className="grid data-point grid-container full-width" key={`data-point-${idx}`} >
                        <div className={`grid-container--left `}>
                            <div className={`grid-container--left image`} style={{ backgroundImage: `url(${img ? img: ''})` }}></div>
                        </div>
                        <div className={`grid-container--right slow--y`}>
                            <div className="grid-container--text slow--y">
                                {pretitle ?
                                    <TagTitle style="slow--y" title={pretitle} />
                                    : null}

                                {title ?
                                    <h2 className="title--l slow--y">
                                        {url ? <a href={url} target={"_blank"} >{title}</a> :  {title}}
                                    </h2>
                                    : null}

                                {subTitle ? (
                                    <div className="mt-2">
                                        <div className="body--m slow--y" dangerouslySetInnerHTML={{ __html: subTitle }}></div>
                                    </div>
                                ) : null}
                                <span className="custom-anchor" id={anchor} />
                                {url ?
                                    <div className="mt-3"><Link className="slow--y" type="arrow" path={url} label={labelLinkText} target={"_blank"} /></div>
                                    : null}

                                
                            </div>
                            <InputRangeSlider
                                framesCount={frames.length}
                                noIndicator
                                activeFrame={parseInt(activeFrame)}
                                changeHandler={setActiveFrame}
                                whiteThumb={false}
                            />
                            <div className="slider-controls slow--y">
                                <button ref={prevButton} className="prev-button" onClick={prevFrame}>
                                    <IconArrowRightLong />
                                </button>
                                <button ref={nextButton} className="next-button" onClick={nextFrame}>
                                    <IconArrowRightLong />
                                </button>
                            </div>
                        </div>
                    </div>
                );
            })}
        </section>
    );
};

export default ExternalArticlesSlider;
