import { useState, useEffect } from 'react';

function useDeviceType() {
    const [deviceType, setDeviceType] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia('(max-width: 767px)').matches) {
                setDeviceType('mobile');

            } else if (window.matchMedia('(max-width: 1023px)').matches) {
                setDeviceType('tablet');
            } else {
                setDeviceType('desktop');

            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return deviceType;
}

export default useDeviceType;
