import React, { useEffect } from 'react';

import { ReactComponent as EnergyIcon } from 'Icons/soluciones/energia.svg';
import { ReactComponent as CiudadesIcon } from 'Icons/soluciones/ciudad.svg';
import { ReactComponent as MovilidadIcon } from 'Icons/soluciones/movilidad.svg';
import { ReactComponent as AguaIcon } from 'Icons/soluciones/agua.svg';
import { ReactComponent as SocialIcon } from 'Icons/soluciones/social.svg';
import { ReactComponent as CapitalIcon } from 'Icons/soluciones/capital.svg';
import { ReactComponent as InmobiliariaIcon } from 'Icons/soluciones/inmobiliaria.svg';

import './styles.scss';

const SolucionesFixed = ({ culture }) => {

    useEffect(() => {
        const appWrapper = document.querySelector('.app--layout');

        appWrapper.addEventListener('scroll', function () {
            let mainHeight = document.getElementById('main').getBoundingClientRect().height;
            let winScroll = appWrapper.scrollTop || appWrapper.scrollTop;
            let solucionesBotonera = document.getElementById('soluciones-botonera').getBoundingClientRect().bottom;
            let solucionesFixed = document.getElementById('soluciones-fixed');

            if (solucionesBotonera < -10) {
                solucionesFixed.classList.add('is-fixed');
            } else {
                solucionesFixed.classList.remove('is-fixed');
            }


            if (winScroll > mainHeight) {
                solucionesFixed.classList.add('is-done');
            } else {
                solucionesFixed.classList.remove('is-done');
            }
        });
    });

    return (
        <span>
            <div className="soluciones-fixed" id="soluciones-fixed">
                <ul>
                    <li className="title--xs">
                        <a href={culture === 'es' ? '#energia' : '#energy'}>
                            <EnergyIcon viewBox="0 0 24 24" />
                            {culture === 'es' ? 'Energía' : 'Energy'}
                        </a>
                    </li>
                    <li className="title--xs">
                        <a href={culture === 'es' ? '#transporte' : '#transport'}>
                            <MovilidadIcon viewBox="0 0 24 24" />
                            {culture === 'es' ? 'Transporte' : 'Transport'}
                        </a>
                    </li>
                    <li className="title--xs">
                        <a href={culture === 'es' ? '#agua' : '#water'}>
                            <AguaIcon viewBox="0 0 24 24" />
                            {culture === 'es' ? 'Agua' : 'Water'}
                        </a>
                    </li>
                    <li className="title--xs">
                        <a href={culture === 'es' ? '#sociales' : '#social'}>
                            <SocialIcon viewBox="0 0 24 24" />
                            {culture === 'es' ? 'Sociales' : 'Social'}
                        </a>
                    </li>
                    <li className="title--xs">
                        <a href={culture === 'es' ? '#ciudades' : '#cities'}>
                            <CiudadesIcon viewBox="0 0 24 24" />
                            {culture === 'es' ? 'Ciudades' : 'Cities'}
                        </a>
                    </li>

                    <li className="title--xs">
                        <a href={culture === 'es' ? '#inmobiliaria' : '#real-estate'}>
                            <InmobiliariaIcon viewBox="0 0 24 24" />
                            {culture === 'es' ? 'Inmobiliaria' : 'Real Estate'}
                        </a>
                    </li>
                    <li className="title--xs">
                        <a href={culture === 'es' ? '#financieras' : '#financial'}>
                            <CapitalIcon viewBox="0 0 24 24" />
                            {culture === 'es' ? 'Financieras' : 'Financial'}
                        </a>
                    </li>
                </ul>
            </div></span>
    );
};

export default SolucionesFixed;
