import React, { useRef } from 'react';

import { useStickyHeader } from 'React/custom_hooks';
import { Link } from 'React/components';

import './HeaderLanding.scss';
import LogoFull from 'Images/logos/logo_full.svg';
import Logo from 'Images/logos/logo.svg';
import LogoAcciona from 'Images/logos/acciona-sainz-xe-team.jpg';
import LogoCampus from 'Images/logos/LogoCampus_acciona.svg';
import LogoEnePol from 'Images/logos/logo_full_ae.svg';


const HeaderLanding = ({ stickyHeader = true, logo, hrefLogo, homeLink, secondHrefLogo, secondLogo, noSlogan, contentPath, logoAltText }) => {
    const headerRef = useRef();

    // STICKY HEADER ON SCROLL UP
    useStickyHeader(headerRef, stickyHeader);

    const isCampus = contentPath.includes("campus");

    const stepOne = contentPath.includes("acciona-energia-poland")
                    || contentPath.includes("certificados-de-ahorro-energetico")
                    || contentPath.includes("planta-biomasa-logrosan")
                    || contentPath.includes("parque-eolico-san-roman")
                    || contentPath.includes("parque-eolico-palmas-altas")
                    || contentPath.includes("planta-fotovoltaica-bolarque")
                    || contentPath.includes("parque-eolico-celada-fusion")
                    || contentPath.includes("planta-fotovoltaica-extremadura")
                    || contentPath.includes("plantas-fotovoltaicas-escepar-y-peralejo")
                    || contentPath.includes("parque-eolico-pedregales")
                    || contentPath.includes("red-tailed-hawk-solar-farm")
                    || contentPath.includes("madison-county-solar-farm")
                    || contentPath.includes("jelinak-wind-farm")
                    || contentPath.includes("union-solar-farm")
                    || contentPath.includes("mason-solar-farm")
                    || contentPath.includes("fleming-solar-farm")
                    || contentPath.includes("krobia-poniec-wind-farm")
                    || contentPath.includes("vjetroelektrana-jelinak")
                    || contentPath.includes("dymerkapv")
                    || contentPath.includes("krobia-i-poniec-ii-wind-farms")
                    || contentPath.includes("palmas-altas-wind-farm")
                    || contentPath.includes("san-roman-wind-farm")
                    || contentPath.includes("fort-bend-solar-farm")
                    || contentPath.includes("high-point-solar-farm")
                    || contentPath.includes("will-county-solar-farm")
                    || contentPath.includes("hudzovkapv")
                    || contentPath.includes("artsyzpv");

    const stepTwo =  !contentPath.includes("acciona-energia-poland")
                        && !contentPath.includes("certificados-de-ahorro-energetico")
                        && !contentPath.includes("planta-biomasa-logrosan")
                        && !contentPath.includes("parque-eolico-san-roman")
                        && !contentPath.includes("parque-eolico-palmas-altas")
                        && !contentPath.includes("planta-fotovoltaica-bolarque")
                        && !contentPath.includes("parque-eolico-celada-fusion")
                        && !contentPath.includes("planta-fotovoltaica-extremadura")
                        && !contentPath.includes("plantas-fotovoltaicas-escepar-y-peralejo")
                        && !contentPath.includes("parque-eolico-pedregales")
                        && !contentPath.includes("red-tailed-hawk-solar-farm")
                        && !contentPath.includes("madison-county-solar-farm")
                        && !contentPath.includes("jelinak-wind-farm")
                        && !contentPath.includes("union-solar-farm")
                        && !contentPath.includes("mason-solar-farm")
                        && !contentPath.includes("fleming-solar-farm")
                        && !contentPath.includes("krobia-poniec-wind-farm")
                        && !contentPath.includes("vjetroelektrana-jelinak")
                        && !contentPath.includes("dymerkapv")
                        && !contentPath.includes("krobia-i-poniec-ii-wind-farms")
                        && !contentPath.includes("palmas-altas-wind-farm")
                        && !contentPath.includes("san-roman-wind-farm")
                        && !contentPath.includes("fort-bend-solar-farm")
                        && !contentPath.includes("high-point-solar-farm")
                        && !contentPath.includes("will-county-solar-farm")
                        && !contentPath.includes("hudzovkapv")
                        && !contentPath.includes("artsyzpv");

    return (
        <>
            {!logo && stepOne
                && (
                <header className="header-landing" ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={LogoEnePol} alt={logoAltText} title={logoAltText} />
                    </Link>
                </header>
            )}
            {!logo && isCampus && stepTwo
                && (
                <header className="header-landing" ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={LogoCampus} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                </header>
            )}
            {!logo && !isCampus && stepTwo
                && (
                <header className="header-landing" ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className={`logo${noSlogan ? ' logo-sainz' : ''}`} src={noSlogan ? '/static/images/logos/acciona-sainz-xe-team.jpg' : LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                </header>
            )}
            {logo && stepOne
                && (
                <header className="page-header grid header-landing header-landing--logo" ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={LogoEnePol} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                    <Link path={hrefLogo ? hrefLogo.link : '/'} target={hrefLogo ? hrefLogo.target : ''}>
                        <img
                            className="logo"
                            src={logo.url}
                            alt={logo.name}
                            title={logo.title}
                        />
                    </Link>
                    {secondLogo &&
                        <Link path={secondHrefLogo ? secondHrefLogo.link : '/'} target={secondHrefLogo ? secondHrefLogo.target : ''} className="logo-home-right">
                            <img
                                className="logo"
                                src={secondLogo.url}
                                alt={secondLogo.name}
                                title={secondLogo.title}
                            />
                        </Link>
                    }
                </header>
            )}
            {logo && isCampus && stepTwo
                && (
                <header className="page-header grid header-landing header-landing--logo" ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={LogoCampus} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                    <Link path={hrefLogo ? hrefLogo.link : '/'} target={hrefLogo ? hrefLogo.target : ''}>
                        <img
                            className="logo"
                            src={logo.url}
                            alt={logo.name}
                            title={logo.title}
                        />
                    </Link>
                    {secondLogo &&
                        <Link path={secondHrefLogo ? secondHrefLogo.link : '/'} target={secondHrefLogo ? secondHrefLogo.target : ''} className="logo-home-right">
                            <img
                                className="logo"
                                src={secondLogo.url}
                                alt={secondLogo.name}
                                title={secondLogo.title}
                            />
                        </Link>
                    }
                </header>
            )}
            {logo && !isCampus && stepTwo
                && (
                <header className="page-header grid header-landing header-landing--logo" ref={headerRef} id="header">
                    <Link path={homeLink} className="logo-home-link">
                        <img className="logo" src={noSlogan ? Logo : LogoFull} alt="Logo Acciona" title="Logo Acciona" />
                    </Link>
                    <Link path={hrefLogo ? hrefLogo.link : '/'} target={hrefLogo ? hrefLogo.target : ''}>
                        <img
                            className="logo"
                            src={logo.url}
                            alt={logo.name}
                            title={logo.title}
                        />
                    </Link>
                    {secondLogo &&
                        <Link path={secondHrefLogo ? secondHrefLogo.link : '/'} target={secondHrefLogo ? secondHrefLogo.target : ''} className="logo-home-right">
                            <img
                                className="logo"
                                src={secondLogo.url}
                                alt={secondLogo.name}
                                title={secondLogo.title}
                            />
                        </Link>
                    }
                </header>
            )}
        </>
    );
};

export default HeaderLanding;
