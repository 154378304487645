import React, { useState, useEffect, useRef } from 'react';

import './Footer.scss';

import LogoFull from 'Images/logos/logo_full.svg';

//import { SubscriptionForm } from 'React/layout';
import { Link, SubscriptionForm } from 'React/components';
import { useSlowFade } from 'React/custom_hooks';

// import { ReactComponent as LinkedinIcon } from 'Icons/linkedin.svg';
// import { ReactComponent as FacebookIcon } from 'Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from 'Icons/social_media/twitter-x.svg';
// import { ReactComponent as InstagramIcon } from 'Icons/instagram.svg';
// import { ReactComponent as YoutubeIcon } from 'Icons/youtube.svg';
// import { ReactComponent as TiktokIcon } from 'Icons/tiktok_icon.svg';

import { IconsSocialMedia } from '../components/Icons/Icons';

import IconsMap from '../components/Header/_iconsMap';

const Footer = ({ footer, culture,
    labelNewsletterTitle,
    labelNewsletterDescription,
    labelNewsletterCheckbox,
    labelNewsletterConfirmationMsg,
    labelNewsletterValidationErrorMsg,
    emailRegistrationError,
    labelIAcept,
    labelDataProtection,
    labelWriteYourEmail,
    labelConfirmationMsgTitle,
    labelConfirmationMsgDescription,
    labelSubscribedMsgTitle,
    labelSubscribedMsgDescription,
    labelcheckboxError,
    linkedinLink, twitterLink, facebookLink, instagramLink, youtubeLink, tiktokLink, homeLink, logoAltText }) => {
    let moduleRef = React.createRef();

    const { contentPage, solutions, legalPages } = footer;

    useSlowFade(moduleRef);

    const onHover = (event) => {
        event.currentTarget.parentNode.parentNode.classList.add('is-hovered');
    };

    const notHover = (event) => {
        event.currentTarget.parentNode.parentNode.classList.remove('is-hovered');
    };

    const subscriptionFormProps = {
        culture: culture,
        action: '/Subscribe/',
        newsletterType: 'generic',
        title: labelNewsletterTitle,
        descr:
            labelNewsletterDescription,
        checkboxLabel: labelNewsletterCheckbox,
        confirmationMsg: labelNewsletterConfirmationMsg,
        errorMsg: labelNewsletterValidationErrorMsg,
        registrationErrorMsg: emailRegistrationError,
        labelIAcept: labelIAcept,
        labelDataProtection: labelDataProtection,
        labelWriteYourEmail: labelWriteYourEmail,
        labelConfirmationMsgTitle: labelConfirmationMsgTitle,
        labelConfirmationMsgDescription: labelConfirmationMsgDescription,
        labelSubscribedMsgTitle: labelSubscribedMsgTitle,
        labelSubscribedMsgDescription: labelSubscribedMsgDescription,
        labelcheckboxError: labelcheckboxError,
        popup: footer.contentPage.newsletterPopup,
        isFooter: true
    };

    return (
        <footer ref={moduleRef} className="page-footer slow--y" id="page-footer">
            <SubscriptionForm {...subscriptionFormProps} />
            <section className="module module-news grid slow--y">
                <div className="grid-container content-wrapper">
                    <div className="module-news__col slow--y">
                        <a
                            href={contentPage.link1 ? contentPage.link1.link : ''}
                            className="link"
                            title={contentPage.title1}
                            data-ref-id="0"
                        >
                            <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                                {contentPage.title1}
                            </h2>
                        </a>
                        <div>
                            <p className="body--m">
                                {contentPage.text1}
                            </p>
                        </div>
                        <Link type="arrow" path={contentPage.link1 ? contentPage.link1.link : ''} className="arrow" />
                    </div>
                    <div className="module-news__col slow--y">
                        <a
                            href={contentPage.link2 ? contentPage.link2.link : ''}
                            className="link"
                            title={contentPage.title2}
                            data-ref-id="1"
                        >
                            <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                                {contentPage.title2}
                            </h2>
                        </a>
                        <div>
                            <p className="body--m">
                                {contentPage.text2}
                            </p>
                            <Link type="arrow" path={contentPage.link2 ? contentPage.link2.link : ''} className="arrow" />
                        </div>
                    </div>
                    <div className="module-news__col slow--y">
                        <a href={contentPage.link3 ? contentPage.link3.link : ''} className="link" title="Descubre nuestras ofertas de empleo" data-ref-id="2">
                            <h2 className="title--s" onMouseOut={notHover} onMouseOver={onHover}>
                                {contentPage.title3}
                            </h2>
                        </a>
                        <div>
                            <p className="body--m">
                                {contentPage.text3}
                            </p>
                        </div>
                        <Link type="arrow" path={contentPage.link3 ? contentPage.link3.link : ''} className="arrow" />
                    </div>
                </div>
            </section>
            {solutions &&
                <div className="module grid soluciones-botonera slow--y">
                    <div className="grid-container grid full-width soluciones-botonera__content">
                        <ul className="grid-container slow--y">
                            {solutions.map((solution, idx) => {
                                return (
                                    <li className="title--s slow--y" key={`footer-solution-${idx}`}>
                                        <a href={solution.url}>
                                            {IconsMap[solution.icon]}
                                            {solution.name}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            }
            <div className="nav-footer grid slow--y">
                <div className="grid-container nav-footer-container">
                    <Link className="slow--y logo-home-link" path={homeLink}>
                        <img className="logo" src={LogoFull} alt={logoAltText} title={logoAltText} />
                    </Link>
                    <div className="nav-footer-links slow--y">
                        <div>
                            {legalPages.map((item, idx) => {
                                return (
                                    <a href={item.url} className="taglabel" key={idx}>
                                        {item.name}
                                    </a>
                                );
                            })}
                            {contentPage.canalEticoLink && contentPage.canalEticoText && (
                                <a href={contentPage.canalEticoLink.link} target="_blank" className="taglabel">{contentPage.canalEticoText}</a>
                            )}
                        </div>
                        <span className="copyright-notice">Copyright © 2020 - ACCIONA</span>
                    </div>
                    <div className="nav-footer-social slow--y">
                        <div className="social-media-container">
                            <a className='icon' href={linkedinLink} target="_blank"> {IconsSocialMedia['Linkedin']} </a>
                            <a className='icon' href={youtubeLink} target="_blank">  {IconsSocialMedia['Youtube']}</a>
                            <a className='icon' href={twitterLink} target="_blank">  {IconsSocialMedia['Twitter']}</a>
                            <a className='icon' href={instagramLink} target="_blank">  {IconsSocialMedia['Instagram']}</a>
                            <a className='icon' href={facebookLink} target="_blank">{IconsSocialMedia['Facebook']}</a>
                            <a className='icon' href={tiktokLink} target="_blank">{IconsSocialMedia['Tiktok']}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="acciona-values slow--y" dangerouslySetInnerHTML={{ __html: contentPage.footerQuote }}>
            </div>
        </footer>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};



export default Footer;
