import React, { useEffect } from 'react';
//import { gsap } from 'gsap';

function useImageParallax(imagesParentSelector) {
  if (typeof window !== undefined) {
    useEffect(() => {
      const appWrapper = document.querySelector('.app--layout');
      const elements = document.querySelectorAll(imagesParentSelector);
      let options = {
        root: null,
      };
      // parallax effect
      let observer = new IntersectionObserver(function(entries) {
        entries.forEach((entry) => {});
      }, options);

      for (let elm of elements) {
        observer.observe(elm);
        const offset = appWrapper.scrollTop / 3;
        gsap.to(elm, { y: offset });
      }
    }, [gsap]);
  }
}
export default useImageParallax;
