import React, { useState, useEffect } from 'react';
import { useFade } from 'React/custom_hooks';
import { Link, EnlaceAgrupador } from 'React/components';

import './styles.scss';
import { TagTitle } from '..';

const DistribuidorMultiple = ({ moduleTitle, subModules, anchor }) => {
    let moduleRef = React.createRef();
    useFade(moduleRef);

    if (typeof window !== 'undefined') {
        return (
            <div ref={moduleRef} className="module distribuidor-multiple grid">
                {moduleTitle && <TagTitle style="slow" title={moduleTitle} />}

                {subModules.map(
                    ({ isLogo, title, desktopImage, mobileImage, body, cta, cta2, links, downloadLabel, fileParams, list, listType }, idx) => {
                        var imageUrl = useWindowWidth() >= 1023 ? desktopImage : mobileImage;

                        imageUrl = imageUrl ? imageUrl : desktopImage;

                        return (
                            <div key={`image-frame-${idx}`} className="distribuidor-multiple__item grid full-width slow">
                                {isLogo && (
                                    <div className="image-src slow">
                                        <img src={imageUrl ? imageUrl.url : ''} alt={imageUrl.alt} title={imageUrl.title} />
                                    </div>
                                )}
                                {!isLogo && <div style={{ backgroundImage: `url(${imageUrl ? imageUrl.url : ''})` }} className="image slow"></div>}

                                <div className="content slow">
                                    <h2 className="title--s slow">{title}</h2>
                                    <div className="body--m slow" dangerouslySetInnerHTML={{ __html: body }}></div>
                                    <span className="custom-anchor" id={anchor} />
                                    <div className="links-container slow">
                                        {cta && (
                                            <div>
                                                <Link type="arrow" label={cta.caption} path={cta.link} target={cta.target} />
                                            </div>
                                        )}
                                        {cta2 && (
                                            <div>
                                                <Link type="arrow" label={cta2.caption} path={cta2.link} target={cta2.target} />
                                            </div>
                                        )}
                                        {links && links.length === 1 && (
                                            <div><Link type="download" label={links[0].name ? links[0].name : links[0].title} path={links[0].umbracoFile ? links[0].umbracoFile : links[0].file} fileParams={fileParams} target="_blank" /></div>
                                        )}

                                        {links && links.length > 1 && downloadLabel && (
                                            <div><EnlaceAgrupador links={links} box_label={downloadLabel} /></div>
                                        )}
                                        {links && links.length > 1 && !downloadLabel && (
                                            <ul className="list list-downloads">
                                                {links.map((item, idx) => {
                                                    return (
                                                        <li key={`text-p-${idx}`} className="link--download main-text-paragraph">
                                                            <Link type="download" path={item.file ? item.file : item.umbracoFile} label={item.title ? item.title : item.name} bytes={item.umbracoBytes} extension={item.umbracoExtension} />
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        )}

                                        {list && listType === 'Numerica' &&
                                            <ol className={`list${list.length === 1 ? ' list--single' : ''}`}>
                                                {list.map((item, idx) => {
                                                    return <li key={`text-p-${idx}`} className="body--m main-text-paragraph slow">
                                                        <div className="module-container__list__link-container" dangerouslySetInnerHTML={{ __html: item }} /></li>
                                                })}
                                            </ol>
                                        }
                                        {list && listType !== 'Numerica' &&
                                            <ul className={`list${list.length === 1 ? ' list--single' : ''}`}>
                                                {list.map((item, idx) => {
                                                    return <li key={`text-p-${idx}`} className="body--m main-text-paragraph slow">
                                                        <div className="module-container__list__link-container" dangerouslySetInnerHTML={{ __html: item }} /></li>
                                                })}
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        );
                    }
                )}
            </div>
        );
    }
    else {
        return (

            <div className="module distribuidor-multiple grid">

                {moduleTitle &&
                    <TagTitle title={moduleTitle} />
                }
            </div>
        );
    }
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

export default DistribuidorMultiple;
