import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, EquipoDirectivo, PaginatorSimple } from 'React/components';

const ManagementTeamList = ({ title, subModules, culture }) => {
    const [managementTeamListState, setManagementTeamListState] = useState([]);
    const [managementTeamFilteredListState, setManagementTeamFilteredListState] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);
    const [territoryComboState, setTerritoryComboState] = useState([]);

    const itemPerPage = 12;

    const areasCombo = culture === 'es' ? [{ name: "Corporación", value: "Corporacion" },
    { name: "Energía", value: "Energia" },
    { name: "Infraestructuras", value: "Infraestructuras" },
    { name: "Construcción", value: "Corporación" },
    { name: "Agua", value: "Agua" },
    { name: "Concesiones", value: "Concesiones" },
    { name: "Servicios", value: "Servicios" },
    { name: "Industrial", value: "Industrial" },
    { name: "Inmobiliaria", value: "Inmobiliaria" }] :
        [{ name: "Corporation", value: "Corporacion" },
        { name: "Energy", value: "Energia" },
        { name: "Infrastructure", value: "Infraestructuras" },
        { name: "Construction", value: "Corporación" },
        { name: "Water", value: "Agua" },
        { name: "Concessions", value: "Concesiones" },
        { name: "Services", value: "Servicios" },
        { name: "Industrial", value: "Industrial" },
        { name: "Real Estate", value: "Inmobiliaria" }];

    const territoryCombo = culture === 'es' ? [{ name: "África", value: "Africa" },
    { name: "Asia", value: "Asia" },
    { name: "Europa", value: "Europa" },
    { name: "Latinoamérica", value: "Latinoamerica" },
    { name: "Norteamérica", value: "Norteamerica" },
    { name: "Oceanía", value: "Oceania" },
    { name: "Oriente Medio", value: "Oriente Medio" }] :
        [{ name: "Africa", value: "Africa" },
        { name: "Asia", value: "Asia" },
        { name: "Europe", value: "Europa" },
        { name: "Latin America", value: "Latinoamerica" },
        { name: "North America", value: "Norteamerica" },
        { name: "Oceania", value: "Oceania" },
        { name: "Middle East", value: "Oriente Medio" }];

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const changePage = (page) => {
        setActualPageState(page);

        var teamList = paginate(managementTeamFilteredListState, itemPerPage, page);
        setManagementTeamListState(teamList);
    };

    const nextPage = () => {
        if (actualPageState < pagesListState.length) {
            setActualPageState(actualPageState + 1);
            var teamList = paginate(managementTeamFilteredListState, itemPerPage, actualPageState + 1);
            setManagementTeamListState(teamList);
        }
    };

    const prevPage = () => {
        if (actualPageState > 1) {
            setActualPageState(actualPageState - 1);
            var teamList = paginate(managementTeamFilteredListState, itemPerPage, actualPageState - 1);
            setManagementTeamListState(teamList);
        }
    };

    const filterTerritory = (area) => {
        if (area) {
            var territoryList = [];
            var comboList = [];

            subModules.map((item, idx) => {
                if (item.area.indexOf(area) !== -1) {
                    item.territory.map((terr, idx) => {
                        territoryList.push(terr);
                    });
                }
            });

            var uniqueTerritoryArray = territoryList.filter(function (item, pos) {
                return territoryList.indexOf(item) === pos;
            });

            uniqueTerritoryArray.sort(function (a, b) {
                if (a < b) { return -1; }
                if (a > b) { return 1; }
                return 0;
            });

            territoryCombo.map((comboItem, idx) => {
                if (uniqueTerritoryArray.includes(comboItem.value)) comboList.push(comboItem);
            });

            setTerritoryComboState(comboList);

        }
        else {
            setTerritoryComboState(territoryCombo);
        }
    };

    const setFilter = (area, territory) => {

        var filteredList = [];

        subModules.map((item, idx) => {
            if (!area || item.area.indexOf(area) !== -1) {
                if (!territory || item.territory.indexOf(territory) !== -1) {
                    filteredList.push(item);
                }
            }
        });

        var teamPagesList = [];
        var listSize = Math.ceil(filteredList.length / itemPerPage);

        for (var i = 0; i !== listSize; i++) {
            var page = { num: i + 1 };
            teamPagesList.push(page);
        }

        setPagesListState(teamPagesList);
        setManagementTeamFilteredListState(filteredList);

        var teamList = paginate(filteredList, itemPerPage, actualPageState);
        setManagementTeamListState(teamList);
    };

    useEffect(() => {
        setTerritoryComboState(territoryCombo);
        setManagementTeamFilteredListState(subModules);
        var teamPagesList = [];
        var listSize = Math.ceil(subModules.length / itemPerPage);

        for (var i = 0; i !== listSize; i++) {
            var page = { num: i + 1 };
            teamPagesList.push(page);
        }
        var teamList = paginate(subModules, itemPerPage, actualPageState);
        setManagementTeamListState(teamList);
        setPagesListState(teamPagesList);
    }, [setPagesListState]);

    return (
        <div className=''>
            <BuscadorFiltros title={title} type='equipo' searchFilter={setFilter} areasCombo={areasCombo} yearsCombo={territoryComboState} filterTerritory={filterTerritory} />
            <EquipoDirectivo subModules={managementTeamListState} />
            {pagesListState.length > 1 &&
                <PaginatorSimple pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} />
            }
        </div>
    );
}

export default ManagementTeamList;