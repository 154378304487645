import React, { useState } from 'react';

import IconsMap from './_iconsMap';
import { useSlowFade } from 'React/custom_hooks';

import './Styles.scss';

const mapaWeb = ({ pages, homeUrl }) => {
    const [columnNumber, setColumn] = useState(0);
    let moduleRef = React.createRef();
    useSlowFade(moduleRef);

    return (
        <div ref={moduleRef} className="module-mapa-web module grid">
            <div className="grid-container">
                <div className="item slow--y">
                    <div>
                        <h2 className="title--s">
                            <a href={homeUrl}>Home</a>
                        </h2>
                    </div>
                </div>
                {pages.map((page, idx) => {
                    if (page.name === 'Soluciones' || page.name === 'Solutions') {
                        var array1 = page.subItemsList ? page.subItemsList.slice(0, (page.subItemsList.length / 2) + 1) : [];
                        var array2 = page.subItemsList ? page.subItemsList.slice((page.subItemsList.length / 2)+1, page.subItemsList.length) : [];
                        return (
                            <div className="item">
                                <h2 className="title--s item-container slow--y">
                                    <a href={page.url}>{page.name}</a>
                                </h2>
                                {array1 &&
                                    <ul className="item-container slow--y">
                                        {array1.map((subPage, idx) => {
                                            return (
                                                <li className="title--xs slow--y">
                                                    <a href={subPage.url}>
                                                        {IconsMap[subPage.icon]}
                                                        {subPage.name}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                }
                                {array2 &&
                                    <ul className="item-container slow--y">
                                        {array2.map((subPage, idx) => {
                                            return (
                                                <li className="title--xs slow--y">
                                                    <a href={subPage.url}>
                                                        {IconsMap[subPage.icon]}
                                                        {subPage.name}
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                }
                            </div>
                        );
                    }
                    else {
                        var array1 = page.subItemsList ? page.subItemsList.slice(0, (page.subItemsList.length / 2)) : [];
                        var array2 = page.subItemsList ? page.subItemsList.slice((page.subItemsList.length / 2), page.subItemsList.length) : [];
                        return (
                            <div className="item slow--y">
                                <div className="item-container slow--y">
                                    <h2 className="title--s">
                                        <a href={page.url}>{page.name}</a>
                                    </h2>
                                </div>
                                {array1 && array1.length > 0 &&
                                    <><div className="item-container slow--y">
                                        {array1.map((subPage, idx) => {
                                            return (
                                                <>
                                                    <h3 className="title--xs slow--y">
                                                        <a href={subPage.url}>{subPage.name}</a>
                                                    </h3>
                                                    {subPage.subItemsList && subPage.subItemsList.length > 0 &&
                                                        <nav>
                                                            {subPage.subItemsList.map((subInnerPage, idx) => {
                                                                return (
                                                                    <p className="body--s slow--y" key={`webmap-subInnerPage-${idx}`}>
                                                                        <a href={subInnerPage.url}>{subInnerPage.name}</a>
                                                                    </p>
                                                                );
                                                            })}
                                                        </nav>
                                                    }
                                                </>
                                            );
                                        })}</div>

                                    </>
                                }
                                {array2 && array2.length > 0 &&
                                    <><div className="item-container slow--y">
                                        {array2.map((subPage, idx) => {
                                            return (
                                                <>
                                                    <h3 className="title--xs slow--y">
                                                        <a href={subPage.url}>{subPage.name}</a>
                                                    </h3>
                                                    {subPage.subItemsList && subPage.subItemsList.length > 0 &&
                                                        <nav>
                                                            {subPage.subItemsList.map((subInnerPage, idx) => {
                                                                return (
                                                                    <p className="body--s slow--y" key={`webmap-subInnerPage-${idx}`}>
                                                                        <a href={subInnerPage.url}>{subInnerPage.name}</a>
                                                                    </p>
                                                                );
                                                            })}
                                                        </nav>
                                                    }
                                                </>
                                            );
                                        })}</div>

                                    </>
                                }
                                {/*page.subItemsList && page.subItemsList.length > 0 &&
                                    <><div className="item-container slow--y">
                                        {page.subItemsList.map((subPage, idx) => {
                                            return (
                                                <>
                                                    <h3 className="title--xs slow--y">
                                                        <a href={subPage.url}>{subPage.name}</a>
                                                    </h3>
                                                    {subPage.subItemsList && subPage.subItemsList.length > 0 &&
                                                        <nav>
                                                            {subPage.subItemsList.map((subInnerPage, idx) => {
                                                                return (
                                                                    <p className="body--s slow--y" key={`webmap-subInnerPage-${idx}`}>
                                                                        <a href={subInnerPage.url}>{subInnerPage.name}</a>
                                                                    </p>
                                                                );
                                                            })}
                                                        </nav>
                                                    }
                                                </>
                                            );
                                        })}</div>

                                    </>
                                */}
                            </div>
                        );
                    }
                })}
            </div>
        </div>
    );
};

export default mapaWeb;
