import React, { useState, useEffect, useRef } from 'react';

import './Footer.scss';

import LogoFull from 'Images/logos/logo_full.svg';

import { Link } from 'React/components';
import { useSlowFade } from 'React/custom_hooks';


import { IconsSocialMedia } from '../components/Icons/Icons';

import { ReactComponent as LinkedinIcon } from 'Icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'Icons/facebook.svg';
//import { ReactComponent as TwitterIcon } from 'Icons/twitter.svg';
import { ReactComponent as InstagramIcon } from 'Icons/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'Icons/youtube.svg';
import { ReactComponent as TiktokIcon } from 'Icons/tiktok_icon.svg';

const FooterLanding = ({ footer, linkedinLink, twitterLink, facebookLink, instagramLink, youtubeLink, tiktokLink, homeLink, logoAltText }) => {
    let moduleRef = React.createRef();

    const { contentPage, solutions, legalPages } = footer;

    useSlowFade(moduleRef);

    return (
        <footer ref={moduleRef} className="page-footer slow--y" id="page-footer">
            <div className="nav-footer grid slow--y">
                <div className="grid-container nav-footer-container">
                    <Link className="slow--y logo-home-link" path={homeLink}>
                        <img className="logo" src={LogoFull} alt={logoAltText} title={logoAltText} />
                    </Link>
                    <div className="nav-footer-links slow--y">
                        <div>
                            {legalPages.map((item, idx) => {
                                return (
                                    <a href={item.url} aria-label={item.name} className="taglabel">
                                        {item.name}
                                    </a>
                                );
                            })}
                            {contentPage.canalEticoLink && contentPage.canalEticoText && (
                                <a href={contentPage.canalEticoLink.link} aria-label={contentPage.canalEticoText} target="_blank" className="taglabel">{contentPage.canalEticoText}</a>
                            )}
                        </div>

                        <span className="copyright-notice">Copyright © 2020 ACCIONA</span>
                    </div>
                    <div className="nav-footer-social slow--y social-media-container">
                        {typeof window !== 'undefined' && useWindowWidth() < 1280 ? (
                            <>
                                <a href={linkedinLink} aria-label="LinkedIn" target="_blank">
                                    <LinkedinIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={youtubeLink} aria-label="YouTube" target="_blank">
                                    <YoutubeIcon width="20" height="20" viewBox="0 0 16 11" />
                                </a>
                                <a href={twitterLink} aria-label="Tweeter" target="_blank">
                                    {IconsSocialMedia['TwitterLandingOwnDomain_14']}
                                </a>
                                <a href={instagramLink} aria-label="Instagram" target="_blank">
                                    <InstagramIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={facebookLink} aria-label="Facebook" target="_blank">
                                    <FacebookIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={tiktokLink} aria-label="TikTok" target="_blank">
                                    <TiktokIcon width="16" height="16" viewBox="0 0 400 400" />
                                </a>
                            </>
                        ) : (
                                <>
                                    <a href={instagramLink} aria-label="LinkedIn" target="_blank">
                                        <LinkedinIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href={youtubeLink} aria-label="YouTube" target="_blank">
                                        <YoutubeIcon width="16" height="16" viewBox="0 0 16 11" />
                                    </a>
                                    <a href={twitterLink} aria-label="Tweeter" target="_blank">
                                        {IconsSocialMedia['TwitterLandingOwnDomain']}
                                    </a>
                                    <a href={instagramLink} aria-label="Instagram" target="_blank">
                                        <InstagramIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href={facebookLink} aria-label="Facebook" target="_blank">
                                        <FacebookIcon width="12" height="12" viewBox="0 0 13 13" />
                                    </a>
                                    <a href={tiktokLink} aria-label="TikTok" target="_blank">
                                        <TiktokIcon width="12" height="12" viewBox="0 0 400 400" />
                                    </a>
                                </>
                            )}
                    </div>
                </div>
            </div>
        </footer>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

const subscriptionFormProps = {
    action: '/subscription-API-endpoint',
    newsletterType: 'generic',
    title: 'Suscríbete a nuestra newsletter',
    descr:
        'Recibe cada mes en tu email las novedades de ACCIONA: noticias, ofertas de empleo o artículos de innovación y sostenibilidad.',
    checkboxLabel:
        'Entiendo la <a href="#privacy-policy" class="link--white">Política de Privacidad</a> y <a class="link--white href="#cookies-management">Cookies</a> y acepto recibir comunicaciones comerciales personalizadas.',
    confirmationMsg: '¡Gracias! Nos pondremos lo antes posible en contacto contigo',
};

export default FooterLanding;
