const titleES = `Aviso a los usuarios de la web`;

const titleEN = `NOTICE TO WEBSITE USERS`;

const description1ES = `<p>Por favor lea esto con detenimiento dado que resulta de aplicación a todas las personas que visiten esta página web.</p><br />
<p>El acceso a la información y la documentación contenida en esta sección de nuestra página web está restringido por motivos legales.
Por favor rellene la información que se solicita a continuación a fin de obtener acceso a dicha información restringida.
Los datos personales que se proporcionen no serán guardados y se le requerirán cada vez que intente acceder a la información restringida.
La información que proporcione ha de ser veraz y correcta.</p>`;

const description1EN = `<p>Please read this carefully as it applies to all persons who visit this website.</p><br />
<p>Access to the information and documents on this portion of the website is restricted for regulatory reasons. 
We request that you complete all fields below in order to access this information. This personal data will 
not be retained by us and will be requested of you each time you seek to access this restricted information.
It must be true and accurate.</p>`;

const countryResidenceES = `Introduzca su país de residencia:`;

const countryResidenceEN = `Please enter your country of residence:`;

const physicallyPresentES = `Actualmente estoy físicamente presente en:`;

const physicallyPresentEN = `I am currently physically present in:`;

const description2ES = `<p>
                                Esta página web y la información que aquí se incluye no están destinadas a, y no deben ser accedidas por, o distribuidas o difundidas a, personas residentes o presentes físicamente en Canadá, Australia, Sudáfrica o Japón, ni en ningún otro país en el que el acceso pueda ser ilegal, y no constituye una oferta de venta ni una solicitud de una oferta de compra o adquisición, de ningún tipo de acciones, derechos u otros valores de la Sociedad en Canadá, Australia, Sudáfrica o Japón, ni en ningún otro país en el que sea ilegal realizar una oferta o solicitud de esa naturaleza.</p><br />
                                <p>Esta página web y la información que aquí se incluye no están destinadas a, y no deben ser accedidas por, o distribuidas o difundidas a, personas en los Estados Unidos de América (incluyendo sus territorios y posesiones, los “Estados Unidos”), excepto a personas que sean compradores institucionales cualificados ("qualified institutional buyers" o "QIBs"), tal y como se define dicho término en la Regla 144A de la normativa de mercado de valores de Estados Unidos (U.S. Securities Act of 1933, con sus respectivas modificaciones).</p><br />
                                <p>Las acciones u otros valores de la Sociedad a los que se hace referencia en esta página web no han sido, ni serán, registrados de acuerdo con la normativa de mercado de valores de Estados Unidos (U.S. Securities Act of 1933, con sus respectivas modificaciones), y no pueden ser ofrecidos o vendidos en los Estados Unidos salvo previo registro o al amparo de una exención de registro bajo dicha normativa.
                             </p><br />
                            <p>
                                Esta página web y la información que aquí se incluye se dirigen, en relación al Reino Unido y los Estados Miembros del Espacio Económico Europeo (cada uno de dichos estados, un “Estado Relevante”), a personas que sean inversores cualificados tal y como se define dicho término en el artículo 2(e) del Reglamento de Folletos (“Inversores Cualificados”). Además, en el Reino Unido esta página web y la información que aquí se incluye se dirigen solo a Inversores Cualificados que (i) tengan experiencia profesional en materia de inversiones y que estén amparadas bajo la definición de “investment professionals” prevista en el Artículo 19(5) de la Orden sobre Promoción Financiera de 2005 bajo la Ley de Servicios y Mercados Financieros de 2000 (Financial Services and Markets Act 2000 (Financial Promotion) Order 2005) con sus respectivas modificaciones (la “Orden”), o (ii) sean entidades de valor neto elevado bajo el Artículo 49(2)(a) al (d) de la Orden (colectivamente, “Personas Relevantes”). Aquellas personas que, en un Estado Relevante (excepto el Reino Unido) no sean Inversores Cualificados, y en el Reino Unido aquellas personas que no sean Personas Relevantes, no deberán acceder a, confiar en o actuar en base a la información aquí contenida. Cualquier inversión o actividad de inversión a la que se refiera esta página web o la información aquí contenida se pone a disposición solo: (i) en los Estados Relevantes (excepto el Reino Unido), de Inversores Cualificados; y (ii) en el Reino Unido, de Personas Relevantes. A efectos de este párrafo, “Reglamento de Folletos” se refiere al Reglamento (UE) 2017/1129.</p><br />
                                <p>El acceso a la información y documentación contenida en las siguientes páginas puede ser ilegal en ciertas jurisdicciones, y sólo ciertas categorías de personas están autorizadas a acceder a dicha información y documentos. Todas las personas que residen fuera de España, los Estados Unidos, Canadá, Australia, Sudáfrica y Japón que deseen tener acceso a los documentos contenidos en esta página web primero deben asegurarse de que no están sujetos a leyes o regulaciones locales que prohíban o restrinjan su acceso a esta página web, ni requieran registro o autorización para cualquier adquisición de valores por su parte. No se ha llevado a cabo ningún registro ni se ha solicitado ninguna aprobación fuera de España. La Sociedad no asume ninguna responsabilidad si hay una violación por cualquier persona de las leyes o reglamentos aplicables.
                            </p><br />
                            <p>Certifico que:</p>
                            1. No soy residente ni estoy físicamente presente en Canadá, Australia, Sudáfrica o Japón;
                             <br />
                            2. No soy residente ni estoy físicamente presente en los Estados Unidos, o soy un QIB;
                             <br />
                            3. No soy residente ni estoy físicamente presente en ningún País Miembro, o soy un Inversor Cualificado;
                             <br />
                            4. No soy residente ni estoy físicamente presente en el Reino Unido, o soy una Persona Relevante; y
                            <br />
                            5. Soy residente y estoy físicamente presente (a) en España o (b) fuera de España y cada una de las jurisdicciones mencionadas en las cláusulas (1) a (4) anteriores y, en tal caso,
                             <br />`;

const description2EN = `<p>
                                This website and the information contained herein are not intended for, and must not be accessed by, or distributed or disseminated to, persons who are resident or physically present in Canada, Australia, South Africa or Japan or jurisdictions in which access may be unlawful, and do not constitute an offer to sell or the solicitation of an offer to buy or acquire, any shares, rights or other securities of the Company in Canada, Australia, South Africa or Japan or such other jurisdictions where such offer or solicitation would be unlawful.</p><br />
                            <p>
                                This website and the information contained herein not intended for, and must not be accessed by, or distributed or disseminated to, persons in the United States of America (including its territories and possessions, the “United States”) other than qualified institutional buyers ("QIBs") as defined in Rule 144A under the U.S. Securities Act of 1933, as amended.</p><br />
                            <p>The shares or other securities of the Company referred to on this website have not been and will not be registered under the U.S. Securities Act of 1933, as amended, and may not be offered or sold in the United States absent registration or an applicable exemption from registration thereunder. 
                            </p><br />
                            <p>
                                This website and the information contained herein are directed in the United Kingdom and in each Member State of the European Economic Area (each a “Relevant State”) to persons who are “qualified investors” within the meaning of Article 2(e) of the Prospectus Regulation (“Qualified Investors”). In addition, in the United Kingdom, this website and the information contained herein are directed only at Qualified Investors who (i) have professional experience in matters relating to investments falling within the definition of “investment professionals” in Article 19(5) of the Financial Services and Markets Act 2000 (Financial Promotion) Order 2005, as amended (the “Order”); or (ii) are high net worth entities falling within Article 49(2)(a) to (d) of the Order (all such persons together being referred to as “Relevant Persons”). This website and the information contained herein must not be accessed, acted on or relied on: (i) in any Relevant State other than the United Kingdom, by persons who are not Qualified Investors; and (ii) in the United Kingdom, by persons who are not Relevant Persons. Any investment or investment activity to which this website and the information contained herein relate is available only to: (i) in any Relevant State other than the United Kingdom, Qualified Investors; and (ii) in the United Kingdom, Relevant Persons, and will be engaged in only with such persons. For the purposes of this paragraph, the expression “Prospectus Regulation” means Regulation (EU) 2017/1129. 
                            </p><br />
                            <p>
                                Access to the information and documents contained on this website may be illegal in certain jurisdictions, and only certain categories of persons may be authorized to access such information and documents. All persons residing outside of Spain, the United States, Canada, Australia, South Africa and Japan who wish to have access to the documents contained on this website should first ensure that they are not subject to local laws or regulations that prohibit or restrict their right to access this website, or require registration or approval for any acquisition of securities by them. No such registration or approval has been obtained outside Spain. The Company assumes no responsibility if there is a violation of applicable law and regulations by any person. 
                            </p><br />
                                <p>I therefore certify that:</p>

                            1. I am resident and physically present outside Canada, Australia, South Africa and Japan;
                             <br />
                            2. I am not resident or physically present in the United States, or else I am a QIB;
                             <br />
                            3. I am not resident or physically present in any Relevant State, or else I am a Qualified Investor;
                             <br />
                            4. I am not resident or physically present in the United Kingdom, or else I am a Relevant Person; and 
                            <br />
                            5. I am resident and physically present (a) in Spain or (b) outside Spain and each of the jurisdictions referred to in clauses (1) through (4) above and, in that case, 
                             <br />`;

const physicallyResidentRadioButtonES = `Estoy autorizado a acceder a la información y documentos en esta página web sin estar sujeto a ninguna restricción legal y sin que dicho acceso requiera ninguna actuación adicional por parte de la Sociedad.`;

const physicallyResidentRadioButtonEN = `I am authorized to access the information and documents on this website without being subject to any legal restriction and without any further action required by the Company.`;

const iHaveReadES = `Por medio de la presente manifiesto que he leído y comprendido el contenido del aviso legal reproducido anteriormente y me comprometo a cumplir con las anteriores restricciones.`;

const iHaveReadEN = `I have read and understood the foregoing limitations, I understand it may affect my rights and obligations and agree to be bound and comply with all of the restrictions set forth above.`;

const forbiddenES = `Lamentamos comunicarle que, debido a restricciones regulatorias, no podemos proporcionarle acceso a esta sección de nuestra página web`;

const forbiddenEN = `We regret that, due to regulatory restrictions, we are unable to provide you with access to this section of our website.`;

const investorTextES = `Soy un analista de research y tengo intención o puedo querer publicar un informe de research sobre Corporación ACCIONA Energías Renovables, S.A.`;

const investorTextEN = `I am a research analyst and I intend or may wish to publish a research report on Corporación ACCIONA Energías Renovables, S.A.`;

const investorLegalTextES = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla a bibendum mauris. Nullam a felis viverra magna varius scelerisque. Proin quis nunc vel ligula posuere interdum sed et purus. Vivamus sed nisl efficitur leo ultrices ultrices ut sed nibh. Curabitur nec convallis massa. Donec faucibus metus ligula, eget iaculis urna lacinia eget. Proin elementum neque consequat diam dictum, et mattis dolor aliquet. Fusce ornare, urna quis accumsan porta, neque nunc dignissim augue, et rutrum ipsum nibh nec lacus. Nunc a justo at urna pretium suscipit. Quisque quis purus pellentesque ligula blandit venenatis sed eget urna. Nulla a felis suscipit, convallis lorem at, tincidunt ante. Vivamus aliquam consequat lacus ut consequat. Aliquam lobortis sollicitudin felis eget efficitur. Cras feugiat, orci ut euismod auctor, nisl orci molestie turpis, sit amet feugiat nunc sapien at nisl. Vestibulum pharetra massa ac turpis ultricies, a dictum massa fringilla.

Sed volutpat vestibulum arcu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus ac feugiat nisl. Nam aliquam elementum est, vitae ornare odio feugiat eu. Vestibulum consectetur lacus justo, ac dignissim sapien accumsan at. Aenean et sapien auctor, mollis dolor id, ullamcorper lacus. Aenean sit amet sapien sed libero aliquam scelerisque. Nam scelerisque massa leo, vitae elementum erat efficitur eu. Suspendisse potenti. Aliquam erat volutpat. Donec venenatis orci nec commodo bibendum. Aliquam erat volutpat. Cras viverra tortor elit, aliquam maximus elit dictum in. Aliquam convallis ultrices massa eu consequat. Proin semper sem lacus, vitae luctus justo placerat sed. Praesent sollicitudin orci in tortor tristique ultrices.`;

const investorLegalTextEN = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla a bibendum mauris. Nullam a felis viverra magna varius scelerisque. Proin quis nunc vel ligula posuere interdum sed et purus. Vivamus sed nisl efficitur leo ultrices ultrices ut sed nibh. Curabitur nec convallis massa. Donec faucibus metus ligula, eget iaculis urna lacinia eget. Proin elementum neque consequat diam dictum, et mattis dolor aliquet. Fusce ornare, urna quis accumsan porta, neque nunc dignissim augue, et rutrum ipsum nibh nec lacus. Nunc a justo at urna pretium suscipit. Quisque quis purus pellentesque ligula blandit venenatis sed eget urna. Nulla a felis suscipit, convallis lorem at, tincidunt ante. Vivamus aliquam consequat lacus ut consequat. Aliquam lobortis sollicitudin felis eget efficitur. Cras feugiat, orci ut euismod auctor, nisl orci molestie turpis, sit amet feugiat nunc sapien at nisl. Vestibulum pharetra massa ac turpis ultricies, a dictum massa fringilla.

Sed volutpat vestibulum arcu. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Vivamus ac feugiat nisl. Nam aliquam elementum est, vitae ornare odio feugiat eu. Vestibulum consectetur lacus justo, ac dignissim sapien accumsan at. Aenean et sapien auctor, mollis dolor id, ullamcorper lacus. Aenean sit amet sapien sed libero aliquam scelerisque. Nam scelerisque massa leo, vitae elementum erat efficitur eu. Suspendisse potenti. Aliquam erat volutpat. Donec venenatis orci nec commodo bibendum. Aliquam erat volutpat. Cras viverra tortor elit, aliquam maximus elit dictum in. Aliquam convallis ultrices massa eu consequat. Proin semper sem lacus, vitae luctus justo placerat sed. Praesent sollicitudin orci in tortor tristique ultrices.`;


export {
    description1ES, description1EN, countryResidenceES, countryResidenceEN, physicallyPresentES, physicallyPresentEN,
    description2ES, description2EN, physicallyResidentRadioButtonES, physicallyResidentRadioButtonEN, iHaveReadES,
    iHaveReadEN, forbiddenES, forbiddenEN, titleES, titleEN, investorTextES, investorTextEN, investorLegalTextES, investorLegalTextEN
};