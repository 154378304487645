// this component renders its children and adds context-nav component with position sticky below
// it also passes the 'combined' prop that is used to remove bottom margin on children components
import React from 'react';
import { ReactComponent as EnergiaIcon } from 'Icons/energia-viento.svg';
import IconsMap from './_iconsMap';

import './withSolucionesNav.scss';

const withSolucionesNav = ({ children, type, interior, routes, icon, solution, subMenu }) => {

    //children = [
    //    {
    //        component: 'HeroImage',
    //        props: {
    //            imageUrl: 'https://source.unsplash.com/random/2560x1440?wind',
    //            title: 'Energía limpia para un sistema energético sostenible',
    //            body:
    //                'Nuestra vida depende de servicios energéticos fiables y asequibles, pero todavía 1 de cada 8 personas no tienen acceso a electricidad.',
    //            cta: { type: 'link', target: '#some-cta' },
    //        },
    //    },
    //];

    if (type === 'single' || subMenu.length === 1) {
        return (
            <>
                {/*children.map(child => {
                    return React.cloneElement(child, { combined: true })
                })*/}
                <nav className="module context-nav context-nav--single">
                    <div className="title--s is-active">{IconsMap[icon]}<span>{subMenu[0]._contentName}</span></div>
                </nav>
            </>
        );
    } else {
        return (
            <>
                {/*children.map(child => {
                    return React.cloneElement(child, { combined: true })
                })*/}
                <nav className="module context-nav">
                    {interior &&
                        <div className="title--s interior is-active"><a href={subMenu[0]._contentPath}>{IconsMap[icon]}<span>{subMenu[0]._contentName}</span></a></div>
                    }
                    {!interior &&
                        <div className="title--s is-active">{IconsMap[icon]}<span>{subMenu[0]._contentName}</span></div>
                    }
                    {
                        subMenu.slice(1).map((item, idx) => {
                                return (
                                    <a href={item._contentPath} className={`title--xs ${item.isActive ? 'menu-active' : ''}`}>{item._contentName}</a>
                                );
                        })
                    }
                </nav>
            </>
        )
    }

};

export default withSolucionesNav;
