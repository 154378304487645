import { useState, useEffect } from 'react';

function useCulture() {
  const [culture, setCulture] = useState(null);

  useEffect(() => {
    const htmlLang = document.documentElement.lang;
    setCulture(htmlLang);
  }, []);

  return culture;
}

export default useCulture;
