import React, { useState, useEffect, useRef } from 'react';

import './Footer.scss';

import LogoFull from 'Images/logos/logo_full.svg';
import LogoEnePol from 'Images/logos/logo_full_ae.svg';

import { Link } from 'React/components';
import { useSlowFade } from 'React/custom_hooks';

import { IconsSocialMedia } from '../components/Icons/Icons';

import { ReactComponent as LinkedinIcon } from 'Icons/linkedin.svg';
import { ReactComponent as FacebookIcon } from 'Icons/facebook.svg';
/*import { ReactComponent as TwitterIcon } from 'Icons/twitter.svg';*/
import { ReactComponent as InstagramIcon } from 'Icons/instagram.svg';
import { ReactComponent as YoutubeIcon } from 'Icons/youtube.svg';
import { ReactComponent as TiktokIcon } from 'Icons/tiktok_icon.svg';
import { truncate } from 'lodash';
import { console } from 'window-or-global';

const FooterLandingOwnDomain = ({ footer, linkedinLink, twitterLink, facebookLink, instagramLink, youtubeLink, tiktokLink, homeLink, canonical, logoAltText }) => {
    let moduleRef = React.createRef();

    const { contentPage, contentPath, solutions, legalPages } = footer;

    useSlowFade(moduleRef);

    const stepOne = contentPath.includes("acciona-energia-poland")
        || contentPath.includes("certificados-de-ahorro-energetico")
        || contentPath.includes("planta-biomasa-logrosan")
        || contentPath.includes("parque-eolico-san-roman")
        || contentPath.includes("parque-eolico-palmas-altas")
        || contentPath.includes("planta-fotovoltaica-bolarque")
        || contentPath.includes("parque-eolico-celada-fusion")
        || contentPath.includes("planta-fotovoltaica-extremadura")
        || contentPath.includes("plantas-fotovoltaicas-escepar-y-peralejo")
        || contentPath.includes("parque-eolico-pedregales")
        || contentPath.includes("red-tailed-hawk-solar-farm")
        || contentPath.includes("madison-county-solar-farm")
        || contentPath.includes("jelinak-wind-farm")
        || contentPath.includes("union-solar-farm")
        || contentPath.includes("mason-solar-farm")
        || contentPath.includes("fleming-solar-farm")
        || contentPath.includes("krobia-poniec-wind-farm")
        || contentPath.includes("vjetroelektrana-jelinak")
        || contentPath.includes("dymerkapv")
        || contentPath.includes("krobia-i-poniec-ii-wind-farms")
        || contentPath.includes("palmas-altas-wind-farm")
        || contentPath.includes("san-roman-wind-farm")
        || contentPath.includes("fort-bend-solar-farm")
        || contentPath.includes("high-point-solar-farm")
        || contentPath.includes("will-county-solar-farm")
        || contentPath.includes("hudzovkapv")
        || contentPath.includes("artsyzpv");

    let link = stepOne ? "https://www.acciona-energia.com" : "https://www.acciona.com";
    link = contentPath.startsWith("/es/") ? link + "/es/" : link;

    let altText = "";
    if (stepOne) {
        if (contentPath.startsWith("/es/")) {
            altText = "Logo ACCIONA Energía";
        } else {
            altText = "Logo ACCIONA Energy";
        }
    }

    return (
        <footer ref={moduleRef} className="page-footer slow--y" id="page-footer" data-footer="FooterLandingOwnDomain">
            <div className="nav-footer grid slow--y">
                <div className="grid-container nav-footer-container">
                    {stepOne //Energia
                        && (
                        <Link className="slow--y logo-home-link" path={link}>
                            <img className="logo" src={LogoEnePol} alt={altText} title={altText} />
                        </Link>

                        )}
                    {!stepOne //Corporativo
                        && (
                        <Link className="slow--y logo-home-link" path={link}>
                            <img className="logo" src={LogoFull} alt={logoAltText} title={logoAltText} />
                        </Link>
                        )}
                    
                    <div className="nav-footer-links slow--y">
                        <div>
                            {legalPages.map((item, idx) => {
                                var truncatedUrl;
                                if (item.url?.includes("cookies")) {
                                    truncatedUrl = item.url;
                                    if (item.url.includes("/es/")) {
                                        absoluteUrl = canonical.replace(" ", "") + truncatedUrl.substring(4, truncatedUrl.truncatedUrl);
                                    } else {
                                        absoluteUrl = canonical.replace(" ", "") + item.url;
                                    }
                                } else {
                                    if (stepOne
                                        && (item.url?.includes("politica-privacidad")
                                            || item.url?.includes("nota-legal")
                                            || item.url?.includes("privacy-policy")
                                            || item.url?.includes("legal-note"))) {

                                        absoluteUrl = "https://www.acciona-energia.com" + item.url;
                                    } else {
                                        var absoluteUrl = "https://www.acciona.com" + item.url;
                                    }
                                }

                                return (
                                    <>
                                        <a href={absoluteUrl} target="_blank" className="taglabel">
                                            {item.name}
                                        </a>
                                    </>
                                );
                            })}
                            {contentPage.canalEticoLink && contentPage.canalEticoText && (
                                <a href={contentPage.canalEticoLink.link} target="_blank" className="taglabel">{contentPage.canalEticoText}</a>
                            )}
                        </div>
                        <span className="copyright-notice">Copyright © 2020 ACCIONA</span>
                    </div>
                    <div className="nav-footer-social slow--y social-media-container">
                        {typeof window !== 'undefined' && useWindowWidth() < 1280 ? (
                            <>
                                <a href={linkedinLink} target="_blank">
                                    <LinkedinIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={youtubeLink} target="_blank">
                                    <YoutubeIcon width="20" height="20" viewBox="0 0 16 11" />
                                </a>
                                <a href={twitterLink} target="_blank">
                                    {IconsSocialMedia['Twitter']}
                                </a>
                                <a href={instagramLink} target="_blank">
                                    <InstagramIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={facebookLink} target="_blank">
                                    <FacebookIcon width="16" height="16" viewBox="0 0 13 13" />
                                </a>
                                <a href={tiktokLink} target="_blank">
                                    <TiktokIcon width="16" height="16" viewBox="0 0 400 400" />
                                </a>
                            </>
                        ) : (
                            <>
                                <a href={instagramLink} target="_blank">
                                    <LinkedinIcon width="12" height="12" viewBox="0 0 13 13" />
                                </a>
                                <a href={youtubeLink} target="_blank">
                                    <YoutubeIcon width="16" height="16" viewBox="0 0 16 11" />
                                </a>
                                <a href={twitterLink} target="_blank">
                                    {IconsSocialMedia['TwitterLandingOwnDomain']}
                                </a>
                                <a href={instagramLink} target="_blank">
                                    <InstagramIcon width="12" height="12" viewBox="0 0 13 13" />
                                </a>
                                <a href={facebookLink} target="_blank">
                                    <FacebookIcon width="12" height="12" viewBox="0 0 13 13" />
                                </a>
                                <a href={tiktokLink} target="_blank">
                                    <TiktokIcon width="12" height="12" viewBox="0 0 400 400" />
                                </a>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </footer>
    );
};

const useWindowWidth = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleWindowResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    return windowWidth;
};

const subscriptionFormProps = {
    action: '/subscription-API-endpoint',
    newsletterType: 'generic',
    title: 'Suscríbete a nuestra newsletter',
    descr:
        'Recibe cada mes en tu email las novedades de ACCIONA: noticias, ofertas de empleo o artículos de innovación y sostenibilidad.',
    checkboxLabel:
        'Entiendo la <a href="#privacy-policy" class="link--white">Política de Privacidad</a> y <a class="link--white href="#cookies-management">Cookies</a> y acepto recibir comunicaciones comerciales personalizadas.',
    confirmationMsg: '¡Gracias! Nos pondremos lo antes posible en contacto contigo',
};

export default FooterLandingOwnDomain;
