import React, { useState } from 'react';

import './Styles.scss';

const TextArea = ({ label, initValue = '', name, errorMsg }) => {
    const [value, setValue] = useState(initValue);

    return (
        <div className="text_area" style={{ marginBottom: 30 }}>
            {label && <p className="form_section_title body--l">{label}</p>}
            <textarea
                className={`${value.trim().length && 'not-empty'} body--m`}
                onChange={(e) => setValue(e.target.value)}
                value={value}
                name={name}
            ></textarea>
            <span className="error_msg" dangerouslySetInnerHTML={{ __html: errorMsg }}></span>
        </div>
    );
};

export default TextArea;
