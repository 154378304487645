import React, { useState, useEffect } from 'react';
import { BuscadorFiltros, Distribuidor2Col, PaginatorSimple, Lightbox, Wysiwyg } from 'React/components';
import { rndKeyGen } from 'React/helpers';

const OwnTechList = ({ subModules, itemsByPage, popup, privacyPolityHtml, labelPrivacyPolicy }) => {
    const [ownTechListState, setOwnTechListState] = useState([]);
    const [ownTechPaginatedListState, setOwnTechPaginatedListState] = useState([]);
    const [pagesListState, setPagesListState] = useState([]);
    const [actualPageState, setActualPageState] = useState(1);

    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');

    const handleLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };

    const itemPerPage = parseInt(itemsByPage) !== 0 ? parseInt(itemsByPage) : 4;

    function paginate(array, page_size, page_number) {
        // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
        return array.slice((page_number - 1) * page_size, page_number * page_size);
    }

    const changePage = (page) => {
        document.getElementById("main").scrollIntoView();
        setActualPageState(page);

        var ownTechList = paginate(ownTechPaginatedListState, itemPerPage, page);
        setOwnTechListState(ownTechList);
    };

    const nextPage = () => {
        if (actualPageState < pagesListState.length) {
            document.getElementById("main").scrollIntoView();
            setActualPageState(actualPageState + 1);
            var ownTechList = paginate(ownTechPaginatedListState, itemPerPage, actualPageState + 1);
            setOwnTechListState(ownTechList);
        }
    };

    const prevPage = () => {
        if (actualPageState > 1) {
            document.getElementById("main").scrollIntoView();
            setActualPageState(actualPageState - 1);
            var ownTechList = paginate(ownTechPaginatedListState, itemPerPage, actualPageState - 1);
            setOwnTechListState(ownTechList);
        }
    };

    useEffect(() => {
        setOwnTechPaginatedListState(subModules);
        var teamPagesList = [];
        var listSize = Math.ceil(subModules.length / itemPerPage);

        for (var i = 0; i !== listSize; i++) {
            var page = { num: i + 1 };
            teamPagesList.push(page);
        }
        var ownTechList = paginate(subModules, itemPerPage, actualPageState);
        setOwnTechListState(ownTechList);
        setPagesListState(teamPagesList);
    }, [setPagesListState]);

    if (ownTechListState.length > 0) {
        return (
            <>
                <div className='module pagination-list-container' id="ownTechList">
                    <Distribuidor2Col subModules={ownTechListState} anchor="ownTechList" />
                    {pagesListState.length > 1 &&
                        <PaginatorSimple pagesList={pagesListState} actualPage={actualPageState} changePage={changePage} nextPage={nextPage} prevPage={prevPage} />
                    }
                    {popup &&
                        <p className="body--s data-handling-link" style={{ /*marginTop: -175, textAlign: 'center'*/ }}>
                            {privacyPolityHtml} <a href="#" onClick={() => handleLightboxClick(popup.text)}>{labelPrivacyPolicy}</a>
                        </p>
                    }
                    <div>
                        <Lightbox
                            key={rndKeyGen()}
                            isVisible={visibleLightbox}
                            closeMethod={() => setVisibleLightbox(null)}
                        >
                            <Wysiwyg html={lightboxContent} />
                        </Lightbox>
                    </div>
                </div>
            </>
        );
    }
    else {
        return (<></>);
    }

}

export default OwnTechList;