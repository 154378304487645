import React from 'react';

import 'Styles/_texts.scss';
import './style.scss';

const WysiwygNoticia = ({ media, body, insertarTweet }) => {

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
    }

    function escapeRegExp(string) {
        return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
    }

    media ? media.url = replaceAll(media.url, "https://accionacorp.blob.core.windows.net", "https://mediacdn.acciona.com") : null;
    body = replaceAll(body, "https://accionacorp.blob.core.windows.net", "https://mediacdn.acciona.com");
    if (body.includes('src="/media/')) body = replaceAll(body, 'src="/media/', 'src="https://mediacdn.acciona.com/media/');
    if (body.includes('href="/media/')) body = replaceAll(body, 'href="/media/', 'href="https://mediacdn.acciona.com/media/');
    if (media && !media.url.includes("mediacdn.acciona.com") && !media.url.includes("accionacorp.blob.core.windows.net")) media.url = "";
    body = replaceAll(body, "/en/", "/");

    return (
        <div className="module module-wysiwyg">
            <div className="grid">
                {media && <div className="image-featured"><div className="image-content"><img src={media.url} alt={media.alt ? media.alt : ''} title={media.title ? media.title : ''} /></div></div>}
                <div className="text-container" dangerouslySetInnerHTML={{ __html: body }}></div>
                {insertarTweet && <div className="text-container article__twitter-embed-wrapper" dangerouslySetInnerHTML={{ __html: insertarTweet }}></div>}
            </div>
        </div>
    )
};

export default WysiwygNoticia;
