import React from 'react';
import { TagTitle } from 'React/components';
import { ReactComponent as DownloadIcon } from 'Icons/download.svg';

import './styles.scss';

const Soluciones = ({ tagLine, items }) => {

    items = [
        {
            image: '/static/formatos/formato_contenido_L.jpg',
            title: 'Nuestro impacto positivo en la tierra',
            leadText: 'Civil',
        },
    ];

    return (
        <div className='module module-soluciones'>
            <div className="grid">{tagLine &&
                <TagTitle title={tagLine} />
            }</div>
            <div className="module-soluciones__container">
                {items.map((item, idx) => {

                    const { icon, image, title, leadText, cifras, cifrasText, cifrasUnit } = item;

                    return (
                        <div key={`data-point-${idx}`} className="module-soluciones__item">
                            <div className="image-container" style={{ backgroundImage: `url(${image})` }}>
                            </div>
                            <div className="content grid">
                                <div className="content-container grid-container">
                                    {cifras &&
                                        <h2 className="title title--cifras">
                                            <span className="lead-text cifras--l">{cifras}
                                                {cifrasUnit &&
                                                    <span className="unit">{cifrasUnit}</span>
                                                }
                                            </span>
                                            <span className="title--xs">{cifrasText}</span>
                                        </h2>
                                    }
                                    {title &&

                                        <h2 className="title--m title"><span>{title}</span></h2>
                                    }
                                    {leadText &&

                                        <div className="lead-text-container">
                                            <p className="lead-text title--m"><img src={icon} /> {leadText}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div></div>
    )
};

export default Soluciones;
