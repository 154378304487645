import React, { useState } from 'react';
import { useSlowFade } from 'React/custom_hooks';

import { rndKeyGen } from 'React/helpers';
import { Link, Lightbox, Wysiwyg } from 'React/components';
import './styles.scss';

const DevelopmentGoals = ({ title, description, cta, subModules, anchor, culture }) => {
    let moduleRef = React.createRef();
    useSlowFade(moduleRef);
    const [visibleLightbox, setVisibleLightbox] = useState(null);

    const goalsArray = subModules.reduce((acc, el, idx) => {
        acc[el.number] = el;
        return acc;
    }, []);

    const goalsWithLightbox = goalsArray.filter((goal) => goal.content !== '');

    const accionaDomain = "https://www.acciona.com";

    const handleGoalClick = (number) => {
        const clickedGoal = goalsArray[number];
        const arrayTitlesES = ["fin de la pobreza", "hambre cero", "salud y bienestar", "Educacion de calidad", "igualdad de genero",
            "agua limpia y saneamiento", "energia asequible y no contaminante", "trabajo decente y crecimiento economico",
            "industria, innovacion e infraestructura", "reduccion de las desigualdades", "ciudades y comunidades sostenibles",
            "produccion y consumo responsables", "accion por el clima", "vida submarina", "vida de ecosistemas terrestres",
            "paz, justicia e instituciones solidas", "alianzas para lograr los objetivos"];

        const arrayTitlesEN = ["no poverty", "zero hunger", "good health and well-being", "quality education", "gender equality",
            "clean water and sanitation", "affordable and clean energy", "decent work and economic growth",
            "industry, innovation and infrastructure", "reduced inequalities", "sustainable cities and communities",
            "responsible consumption and production", "climate action", "life below water", "life on land",
            "peace, justice and string institutions", "partnerships for the goals"];

        if (clickedGoal && clickedGoal.content !== '') {
            window.dataLayer.push({
                event: "ga_event",
                event_name: "development_goals_unfold",
                event_category: "development goals unfold",
                event_action: culture === 'es' ? arrayTitlesES[number - 1] : arrayTitlesEN[number - 1],
                event_label: undefined,
                event_value: undefined,
                title: culture === 'es' ? arrayTitlesES[number - 1] : arrayTitlesEN[number - 1]
            });
            setVisibleLightbox(number);
        }
    };

    const goalsImagesUrlPrefix = culture === 'es' ?
        '/static/development_goals_images/Goal_' : '/static/development_goals_images_en/Goal_';

    let clearLink = cta && cta.link ? cta.link.startsWith('http') ? cta.link : accionaDomain + cta.link : '';

    return (
        <>
            <div ref={moduleRef} id="devGoals" className="module grid development-goals">
                <h2 className="title title--l slow--y">{title}</h2>
                <div className="description body--m slow--y">
                    {description}

                    {cta && (
                       
                        <p className="slow--y spaced">
                            <Link className="module--cta" type="arrow" path={clearLink} target={cta.target}>
                                {cta.caption}
                            </Link>
                        </p>
                    )}

                </div>
                <span className="custom-anchor" id={anchor} />
                <div className="goals-buttons slow--y">
                    {Array.from(new Array(18), (goal, idx) => {
                        const currentGoal = goalsArray[idx];
                        if (currentGoal && currentGoal.content === '') {
                            // external link
                            if (currentGoal && currentGoal.cta && currentGoal.cta.type === 'External') {
                                return (
                                    <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                                        <Link path={currentGoal.cta.link} target={currentGoal.cta.target}>
                                            <img src={goalsImagesUrlPrefix + idx + '.png'} key={rndKeyGen()} />
                                        </Link>
                                    </div>
                                );
                            }
                            // internal link
                            if (currentGoal && currentGoal.cta && currentGoal.cta.type === 'Internal') {
                                return (
                                    <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                                        <Link path={currentGoal.cta.link} target={currentGoal.cta.target}>
                                            <img src={goalsImagesUrlPrefix + idx + '.png'} key={rndKeyGen()} />
                                        </Link>
                                    </div>
                                );
                            }

                        }
                        // lightbox
                        return (
                            <div className="goal-button" key={rndKeyGen()} data-is-active={currentGoal !== undefined}>
                                <img
                                    data-is-active={currentGoal !== undefined}
                                    onClick={() => handleGoalClick(idx)}
                                    src={goalsImagesUrlPrefix + idx + '.png'}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
            {goalsWithLightbox.map((goal, idx) => {
                return (
                    <Lightbox
                        key={rndKeyGen()}
                        isVisible={visibleLightbox === goal.number}
                        closeMethod={() => setVisibleLightbox(null)}
                    >
                        <Wysiwyg html={goal.content} links={goal.popupLinks} />
                    </Lightbox>
                );
            })}
        </>
    );
}

export default DevelopmentGoals;
