import React, { useEffect, useState } from 'react';

import { Link, TagTitle } from 'React/components';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';
import IconsMap from './_iconsMap';

import { useObserver } from 'React/custom_hooks';

import './style.scss';

const ModuleNews = ({ tagLine, content, moduleCta, type, culture, handleLegalLightboxClick }) => {

    const newsCol = content.map((item, idx) => {
        if (item) {
            const refList = content.map((item) => React.createRef());

            if (typeof window !== 'undefined') {
                const [observer, setElements, entries] = useObserver({
                    root: null,
                    threshold: 0.25,
                });

                const tl = new TimelineMax({ delay: 0, repeat: 0 });

                useEffect(() => {
                    // setting elements to observe
                    const elements = document.querySelectorAll('.module-news .slow--y');
                    TweenMax.set(elements, { opacity: 0, y: 90 });
                    setElements(elements);
                }, [setElements]);

                useEffect(() => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            let lazyItem = entry.target;
                            tl.to(lazyItem, 1, { opacity: 1, y: 0 }, 0.2);
                            observer.unobserve(lazyItem);
                        }
                    });
                }, [entries, observer, TimelineMax]);
            }

            const onHover = (event) => {
                const { refId } = event.currentTarget.dataset;
                refList[parseInt(refId)].current.classList.add('is-hovered');
            };

            const notHover = (event) => {
                const { refId } = event.currentTarget.dataset;
                refList[parseInt(refId)].current.classList.remove('is-hovered');
            };

            const maxTextLength = 100;

            if (type === 'hechos') {
                const { title, file, date, thumbnail, recordNumber, CNMVType, popUpLegal, legalPopup } = item;

                const monthNames = culture === 'es' ? ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                    "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
                ] : ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"
                    ];

                var dateObj = new Date(date);
                var day = dateObj.getDate();
                var year = dateObj.getFullYear();
                var month = monthNames[dateObj.getMonth()];

                return (
                    <div className="module-news__col">
                        {title && (!popUpLegal && (!legalPopup || legalPopup === '[null]' || legalPopup === '[]')) && (
                            <a href={file ? file : ''} target={file ? '_blank' : ''} className="link slow--y" title={title}>
                                <h2 className="title--s">{title}</h2>
                            </a>
                        )}
                        {title && (popUpLegal || (legalPopup && legalPopup !== '[null]' && legalPopup !== '[]')) && (
                            <a href="#" className="link slow--y" title={title} onClick={() => handleLegalLightboxClick(true, file, legalPopup ? legalPopup : "Popup-energia")}>
                                <h2 className="title--s">{title}</h2>
                            </a>
                        )}
                        {date && year !== 1 &&
                            <div className="tag title--small slow--y">
                                <span className="date-calendar">
                                    <IconCalendar />
                                    {`${day} ${month} ${year}`}
                                </span>
                            </div>
                        }
                        {recordNumber && file && (!popUpLegal && (!legalPopup || legalPopup === '[null]' || legalPopup === '[]')) &&
                            <div className="slow--y">
                                <Link type="download" label={culture === 'es' ? `Registro: ${recordNumber}` : `Register: ${recordNumber}`} path={file} target="_blank" />
                            </div>
                        }
                        {recordNumber && file && (popUpLegal || (legalPopup && legalPopup !== '[null]' && legalPopup !== '[]')) &&
                            <div className="slow--y">
                                <Link type="legalPopup" label={culture === 'es' ? `Registro: ${recordNumber}` : `Register: ${recordNumber}`} onClickFunction={() => handleLegalLightboxClick(true, file, legalPopup ? legalPopup : "Popup-energia")} />
                            </div>
                        }
                    </div>
                );
            } else {
                const { title, soluciones, tag, date, intro, link } = item;

                return (
                    <div data-component="ModuleNews" className="module-news__col slow--y" key={`news-${idx}`} ref={refList[idx]}>
                        {title && (
                            <a href={link} className="link module-news__col__title-link" title={title} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover}>
                                <h2 className="title--s">{title}</h2>
                            </a>
                        )}

                        {soluciones &&
                            <div className="tag title--small" key={`tag-${idx}`}>
                                {soluciones.slice(0, 2).map((solution, idx) => {
                                    return (
                                        <>
                                            <a href={solution.url} key={`solution-tag-${idx}`} className="link icon--red" title={solution.name}>
                                                {solution.icon && IconsMap[solution.icon]}
                                                {solution && <span>{solution.name}</span>}
                                            </a>
                                            {idx === 0 && soluciones.length > 1 && <span>,&nbsp;</span>}
                                        </>

                                    );
                                })}
                                {date && <span className="date">{date}</span>}
                            </div>
                        }
                        {intro &&
                            <div>
                                <div className="body--m" dangerouslySetInnerHTML={{ __html: intro.length < maxTextLength ? intro : `${intro.substr(0, maxTextLength)}...` }}></div>
                            </div>
                        }
                        {link &&
                            <Link type="arrowLong" className="arrow" path={link} />
                        }

                    </div>
                );
            }
        }
    });

    return (
        <>
            <section data-component="ModuleNews" className="module module-news grid">
                {tagLine && (
                    <div className="module-news__header slow--y">
                        <TagTitle title={tagLine} />
                        <Link type="arrow" label="Ver todas las noticias" />
                    </div>
                )}

                <div className="grid-container content-wrapper">{newsCol}</div>
                {moduleCta && (
                    <div className="cta-footer">
                        <Link type="arrow" label={moduleCta.caption} path={moduleCta.link} />
                    </div>
                )}
            </section>
        </>
    );
};

export default ModuleNews;
