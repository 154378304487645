import React, { useState, useContext } from 'react';
import useDeviceType from '../../../../custom_hooks/useDeviceType';
import { useSlowFade } from 'React/custom_hooks';
import { IconsMap } from '../../../Icons/Icons';
import { TagTitle, Link } from 'React/components';
import { removeAccents } from '../../../../helpers';

const SolutionAreas = (props) => {
  const { tagTitle, cta, headers, _contentPath } = props;
  const subModules = props.subModules;

  const solutionLink = _contentPath.startsWith('/es/')
    ? '/es/soluciones/'
    : '/solutions/';

  const data = headers.map((header, idx) => ({
    header,
    subModules: subModules.map((row) => row.subModules[idx]),
    linkToSolution: `${solutionLink}${removeAccents(header)}/`,
  }));

  const device = useDeviceType();
  const [expandedSolutionIndices, setExpandedSolutionIndices] = useState({});

  let moduleRef = React.createRef();
  useSlowFade(moduleRef);

  const toggleVisibility = (idx) => {
    setExpandedSolutionIndices((prevIndices) => ({
      ...prevIndices,
      [idx]: !prevIndices[idx],
    }));
  };

  return (
    <div className="module table-solution-areas">
      <div className="table-solution-title grid">
        {tagTitle && <TagTitle title={tagTitle} />}
      </div>

      <div className="solution-areas">
        {data.map((item, idx) => (
          <div key={idx} className="container">
            <div className="title-container">
              <span className="solution-areas-icon">
                {IconsMap[item.header.replace(/\s/g, '')]}
              </span>

              <a href={item.linkToSolution}>
                <h1 className="link header-text">{item.header} </h1>
              </a>

              {(device === 'mobile' || device === 'tablet') && (
                <button
                  className="menu-button"
                  onClick={() => toggleVisibility(idx)}
                >
                  {expandedSolutionIndices[idx]
                    ? IconsMap['IconMinus']
                    : IconsMap['IconPlus']}
                </button>
              )}
            </div>
            <div
              className={`area-container ${
                (device === 'mobile' || device === 'tablet') &&
                !expandedSolutionIndices[idx]
                  ? 'collapsed'
                  : ''
              }`}
            >
              <ul className="link area-list">
                {item.subModules.map((areaName, areaIdx) => {
                  const { _documentType, text, className } = areaName;
                  const textOneLine = text.replace('<br />', ' ');
                  return (
                    <>
                      {text && (
                        <div
                          className="--left"
                          dangerouslySetInnerHTML={{ __html: textOneLine }}
                        ></div>
                      )}
                    </>
                  );
                })}
              </ul>

              {(device === 'mobile' || device === 'tablet') && (
                <div className="link-solution">
                  <Link
                    path={item.linkToSolution}
                    label={
                      item.linkToSolution.startsWith('/es/')
                        ? `Ir a ${item.header}`
                        : `Go to ${item.header}`
                    }
                    type={cta.type}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      {cta && (
        <div className="table-solution-title grid">
          <Link label={cta.caption} path={cta.link} type={cta.type} />
        </div>
      )}
    </div>
  );
};

export default SolutionAreas;
