import React from 'react';
import { ReactComponent as IconCivil } from 'Icons/civil.svg';
import { ReactComponent as IconLeaf } from 'Icons/leaf.svg';
import { ReactComponent as IconEnergy } from 'Icons/energia-viento.svg';
import { ReactComponent as IconMovilidad } from 'Icons/movilidad-24.svg';
import { ReactComponent as IconAgua } from 'Icons/water-24.svg';
import { ReactComponent as IconSocial } from 'Icons/social-24.svg';
import { ReactComponent as IconInmo } from 'Icons/inmobiliaria-24.svg';
import { ReactComponent as IconCapital } from 'Icons/capital-24.svg';
import { ReactComponent as IconIndustrial } from 'Icons/industrial-24.svg';
import { ReactComponent as IconOtros } from 'Icons/otrosnegocios-24.svg';

export default {
    Ciudades: <IconCivil height="18" width="18" viewBox="0 0 24 24" />,
    MedioAmbiente: <IconLeaf height="18" width="18" viewBox="0 0 24 24" />,
    Energia: <IconEnergy height="18" width="18" viewBox="0 0 24 24" />,
    Transporte: <IconMovilidad height="18" width="18" viewBox="0 0 24 24" />,
    Agua: <IconAgua height="18" width="18" viewBox="0 0 24 24" />,
    Social: <IconSocial height="18" width="18" viewBox="0 0 24 24" />,
    Inmobiliaria: <IconInmo height="18" width="18" viewBox="0 0 24 24" />,
    Capital: <IconCapital height="18" width="18" viewBox="0 0 24 24" />,
    Industrial: <IconIndustrial height="18" width="18" viewBox="0 0 24 24" />,
    Otros: <IconOtros height="18" width="18" viewBox="0 0 24 24" />,
};
