import React, { useState, useEffect } from 'react';
import { Lightbox, Wysiwyg } from 'React/components';
import { rndKeyGen } from 'React/helpers';

const PrivacyPolicy = ({ popup, position, privacyPolityHtml, labelPrivacyPolicy }) => {
    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');

    const handleLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };

        return (
            <>
                <div className={`module simple-module ${position ? position : 'center'}`}>
                    {popup &&
                        <p className="body--s data-handling-link">
                            {privacyPolityHtml} <a href="#" onClick={() => handleLightboxClick(popup.text)}>{labelPrivacyPolicy}</a>
                        </p>
                    }
                    <div>
                        <Lightbox
                            key={rndKeyGen()}
                            isVisible={visibleLightbox}
                            closeMethod={() => setVisibleLightbox(null)}
                        >
                            <Wysiwyg html={lightboxContent} />
                        </Lightbox>
                    </div>
                </div>
            </>
        );

}

export default PrivacyPolicy;