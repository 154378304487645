// button.component.js
import React from 'react';
import { ReactComponent as Chevron } from 'Icons/chevron.svg';

const SquareCheckbox = ({ name, label, checkboxState, toggleCheckbox, errorMsg }) => {
    return (
        <div className="faux-flex">
            <input
                type="checkbox"
                id={name}
                value="true"
                checked={checkboxState === true}
                onChange={toggleCheckbox}
                name="conditions_accepted"
            />
            <div className="faux-checkbox" onClick={toggleCheckbox} aria-hidden="true">
                <Chevron className="checkbox-tick" />
            </div>
            <label
                className="body--s for-terms-checkbox"

                onClick={toggleCheckbox}
            >{label}
            </label>
        </div>
    );
};

export default SquareCheckbox;
