import React, { useState, useEffect } from 'react';
import useSliderSwipe from 'React/custom_hooks/useSliderSwipe';

import { ReactComponent as IconArrowRight } from 'Icons/iconarrowright.svg';
import { ReactComponent as IconCalendar } from 'Icons/calendar.svg';

import './style.scss';

import { TagTitle, InputRangeSlider, Link } from 'React/components';

const TrabajaConNosotros = ({ title, solutionDropDown, offertsNumber,
    linkToOffers, urlSolution, urlOfferDetail, culture, countriesDropDown, jobTypeDropDown, isLanding, labelNoJobOffers }) => {

    const accionaDomain = isLanding ? "https://www.acciona.com" : "";

    const monthNames = culture === 'en' ? ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ] : ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
        "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
    ];

    const labelDe = culture === 'en' ? "" : "DE";

    var dateFormat = '';
    const getDateFormat = (d) => {
        let date = new Date(d);
        let day = date.getDate();
        let month = monthNames[date.getMonth()];
        let year = date.getFullYear();

        if (culture == "en") {
            dateFormat = `${month} ${day}, ${year}`;
        } else {
            dateFormat = `${day} ${labelDe} ${month}, ${year}`;
        }
    };

    const [lastOffersListState, setlastOffersListState] = useState([]);

    const [activeFrame, setActiveFrame] = useState(1);

    const refList = lastOffersListState.map(frame => React.createRef());

    const onHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId)].current.classList.add('is-hovered');
    };

    const notHover = (event) => {
        const { refId } = event.currentTarget.dataset;
        refList[parseInt(refId)].current.classList.remove('is-hovered');
    };

    const getLastOffersByDivision = (solutionDropDown, countriesDropDown, jobTypeDropDown, page) => {

        if (lastOffersListState.length === 0) {
            //var url = `/api/CmsController/GetListOffers?language=es&division=${solutionDropDown}`;
            var url = `/api/CmsController/GetListOffersPaginated?language=${culture}&pageIndex=${page}&includeFeaturedItems=true&division=${solutionDropDown}&source=${jobTypeDropDown}&country=${countriesDropDown}`;
            const xhrPages = new XMLHttpRequest();
            xhrPages.open('get', url, true);
            xhrPages.onload = function (e) {
                if (xhrPages.readyState === 4) {
                    if (xhrPages.status === 200) {

                        var lastOfferList = JSON.parse(xhrPages.responseText);

                        setlastOffersListState(lastOfferList.offerList.slice(0, offertsNumber));
                    }

                    else {
                        console.error(xhrPages.statusText);
                    }
                }

            }.bind(this);

            xhrPages.send(null);
        }
    };

    const getLastOffers = (page) => {

        var url = `/api/CmsController/GetListOffersPaginated?language=${culture}&pageIndex=${page}&includeFeaturedItems=true`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {

                    var lastOfferList = JSON.parse(xhrPages.responseText);

                    setlastOffersListState(lastOfferList.offerList.slice(0, offertsNumber));
                }

                else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);

    };

    useEffect(() => {
        if (solutionDropDown || countriesDropDown || jobTypeDropDown) {
            solutionDropDown = solutionDropDown === 'Energia' ? 'Energía' : solutionDropDown;
            if (lastOffersListState.length === 0) {
                getLastOffersByDivision(solutionDropDown, countriesDropDown, jobTypeDropDown, 1);
            }
        }
        else {
            if (lastOffersListState.length === 0) {
                getLastOffers(1);
            }
        }
    }, []);

    return (
        <div className="module grid jobs-slider"
            data-active-frame={activeFrame} >

            <TagTitle title={title ? title : ''} />

            <InputRangeSlider
                changeHandler={setActiveFrame}
                framesCount={lastOffersListState.length}
                activeFrame={parseInt(activeFrame)}
                noIndicator
            />

            <div className="jobs-container" >
                {lastOffersListState.length > 0 ? lastOffersListState.map((frame, idx) => {
                    const { id, title, division, region, descriptionSummary, date, country } = frame;
                    var strDivision = division.toLowerCase();
                    strDivision = strDivision === 'energía' ? 'energia' : strDivision;
                    getDateFormat(date);
                    return (
                        <div className="job-description grid" key={`data-point-${idx}`} ref={refList[idx]}>
                            <div className="job-header">
                                <a href={accionaDomain + urlOfferDetail + id} target={isLanding ? "_blank" : ""} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} >
                                    <h2 className="title--s">{title}</h2>
                                </a>
                                <div className="job-subheader">
                                    {region &&
                                        <span className="taglabel">
                                            <a href={`${linkToOffers ? accionaDomain + linkToOffers.link : ''}${country ? '?country=' + country : ''}`} className="job-sector" target={isLanding ? "_blank" : ""}>{region}</a>
                                        </span>
                                    }
                                    <span className="taglabel"><IconCalendar />{dateFormat} </span>
                                </div>
                            </div>
                            <a href={accionaDomain + urlOfferDetail + id} target={isLanding ? "_blank" : ""} data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} className="job-body body--s">
                                {descriptionSummary}
                                <div data-ref-id={idx} onMouseOut={notHover} onMouseOver={onHover} className="job-cta">
                                    <IconArrowRight />
                                </div>
                            </a>
                        </div>
                    );
                }) : <div className="job-body body--m slow--y">{labelNoJobOffers}</div>}
            </div>
            {linkToOffers &&
                <Link className="section-cta" type="arrow" path={`${linkToOffers.link}${solutionDropDown ? '?solution=' + solutionDropDown : ''}`} label={linkToOffers.caption} target={linkToOffers.target} />
            }

        </div>
    );
};

export default TrabajaConNosotros;
