import React, { useState } from 'react';
import { PropTypes as pt } from 'prop-types';
import { rndKeyGen } from 'React/helpers';

import './Styles.scss';

const RadioButton = ({ label, name, options, selected_option, errorMsg, onChangeEvent, required }) => {
    const [selected, setSelected] = useState(selected_option);

    const event = (e) => {
        setSelected(e);
        onChangeEvent ? onChangeEvent(options[e - 1].value) : null;
    };

    return (
        <div className="input_radio">
            {label && <p className="form_section_title radio_title body--l">{label} {required && <span style={{ color: 'red' }}>*</span>}</p>}
            <div style={{ width: '100%', display: 'flex' }}>
                {options.map((option, idx) => (
                    <p className="radio_option" key={rndKeyGen()}>
                        <input
                            type="radio"
                            name={name}
                            id={`${name}-option-${idx + 1}`}
                            value={option.value}
                            checked={selected === idx + 1}
                            onChange={() => event(idx + 1)}
                        />
                        <label htmlFor={`${name}-option-${idx + 1}`}>
                            <span className="radio_ghost"></span>
                        </label>
                        <label className="taglabel" htmlFor={`${name}-option-${idx + 1}`}>
                            {option.label}
                        </label>
                    </p>
                ))}
            </div>
            <span className="error_msg" dangerouslySetInnerHTML={{ __html: errorMsg }}></span>
        </div>
    );
};

RadioButton.propTypes = {
    label: pt.string,
    name: pt.string.isRequired,
    options: pt.arrayOf(
        pt.shape({
            label: pt.string.isRequired,
            value: pt.string.isRequired,
        })
    ).isRequired,
    selected_option: pt.number,
};

export default RadioButton;
