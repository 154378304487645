import React, { useState, useEffect } from 'react';

import { TagTitle, Link } from 'React/components';
import Map from './Map';
import { iconsMap, iconsMapLarge } from './solucionesIconsMap';
import './styles.scss';
import { ReactComponent as CircledArrow } from 'Icons/arrow_circle-68.svg';
import { ReactComponent as CloseIcon } from 'Icons/cross.svg';
import { ReactComponent as CloseIconMedium } from 'Icons/cross-20.svg';
import { rndKeyGen } from 'React/helpers';



const ProjectsMap = ({ model, culture }) => {
    const { title, solution, cta, initMapCenter, projectsList, projectType, lat, lng, zoom, isLanding } = model;
    const baseCenter = { lat: lat ? parseInt(lat) : 40.4167754, lng: lng ? parseInt(lng) : -3.7037902 };
    const mapCenter =
        typeof initMapCenter === 'undefined'
            ? baseCenter
            : typeof initMapCenter === 'number'
                ? projectsList[initMapCenter]
                : initMapCenter;

    const solutionsList = culture === 'es' ? [{ name: 'Energía', value: 'Energia' }, { name: 'Transporte', value: 'Transporte' }, { name: 'Agua', value: 'Agua' },
    { name: 'Sociales', value: 'Social' }, { name: 'Ciudades', value: 'Ciudades' }, { name: 'Inmobiliaria', value: 'Inmobiliaria' },
    { name: 'Financieras', value: 'Capital' }] :
        [{ name: 'Energy', value: 'Energia' }, { name: 'Transport', value: 'Transporte' }, { name: 'Water', value: 'Agua' },
        { name: 'Social', value: 'Social' }, { name: 'Cities', value: 'Ciudades' }, { name: 'Real Estate', value: 'Inmobiliaria' },
        { name: 'Financial', value: 'Capital' }];

    // component state
    const [centerPoint, setCenterPoint] = useState(mapCenter);
    const [zoomValue, setZoomValue] = useState(zoom ? parseInt(zoom) : 2);
    const [selectedSolution, setSelectedSolution] = useState(null);
    const [selectedProject, setSelectedProject] = useState(null);
    const [filteredList, setFilteredList] = useState([]);
    const [solutionsOnMap, setSolutionsOnMap] = useState([]);
    const [init, setInit] = useState(false);

    // filtering
    useEffect(() => {
        var url = `/api/CmsController/GetProjectList?language=${culture}&itemsByPage=9999999&pageIndex=1&projectTypeFilter=${projectType.join(",")}`;
        const xhrPages = new XMLHttpRequest();
        xhrPages.open('get', url, true);
        xhrPages.onload = function (e) {
            if (xhrPages.readyState === 4) {
                if (xhrPages.status === 200) {
                    var responsePaginated = JSON.parse(xhrPages.responseText);

                    var solutionsMap = [...new Set(responsePaginated.projectList.map((project) => project.solution.icon))];

                    var projectList = responsePaginated.projectList.filter((project) => project.lat !== '' && !isNaN(parseFloat(project.lat)) && project.noMap === false);

                    const projects = projectList.map((project) => {
                        project.id = rndKeyGen();
                        project.lng = parseFloat(project.lng);
                        project.lat = parseFloat(project.lat);
                        return project;
                    });

                    var solutionSelected;

                    if (!init) {
                        solutionSelected = selectedSolution ? selectedSolution : solution;
                        setSelectedSolution(solutionSelected);
                    }
                    else { solutionSelected = selectedSolution; }

                    if (init === false) setInit(true);
                    //setSelectedSolution(solutionSelected);

                    if (!solutionSelected) {
                        setFilteredList(projects);
                    } else {
                        setFilteredList(projects.filter((project) => project.solution.icon === solutionSelected));
                    }
                    setSolutionsOnMap(solutionsMap);
                    //setProjectListState(responsePaginated.projectList);

                } else {
                    console.error(xhrPages.statusText);
                }
            }
        }.bind(this);

        xhrPages.send(null);


    }, [selectedSolution]);

    const handleSolutionClick = (solution) => {

        window.dataLayer.push({
            event: "ga_event",
            event_name: "filter_submit",
            event_category: "filter submit",
            event_action: "project map",
            event_label: "solucion : " + solution.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            event_value: undefined,
            category: "project map",
            option: "solucion : " + solution.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
        });

        setSelectedSolution((oldState) => {
            if (oldState === solution) return null;

            if (!solutionsOnMap.includes(solution)) return null;

            return solution;
        });

        // center on base point
        if (solution === selectedSolution) {
            setCenterPoint({ lat: lat ? parseInt(lat) : 40.4167754, lng: lng ? parseInt(lng) : -3.7037902 }); // Madrid, Spain
            setZoomValue(zoom ? parseInt(zoom) : 2);
        }
    };

    const handleCloseOverlayClick = () => {
        setSelectedProject(null);
        if (filteredList.length > 0) {
            setFilteredList((oldState) =>
                oldState.map((project) => {
                    project.selected = false;
                    return project;
                })
            );
        }
    };

    // selected project details
    const changeSelectedPoint = (projectId) => {
        setSelectedProject({
            key: projectId,
            ...filteredList.find((project) => project.id === projectId),
        });
    };

    useEffect(() => {
        if (selectedProject === null) {
            return;
        }

        setCenterPoint(() => {
            const { lat, lng } = selectedProject;
            return { lat, lng };
        });

        // red Marker for selected project
        setFilteredList((oldState) =>
            oldState.map((project) => {
                if (project.id == selectedProject.id) {
                    project.selected = true;
                } else {
                    project.selected = false;
                }
                return project;
            })
        );
    }, [selectedProject]);

    function includeSolution(array, itemToFind) {
        var found = false;
        for (var i = 0; i < array.length; i++) {
            if (array[i].solution.icon === itemToFind) {
                found = true;
                break;
            }
        }
    }

    if (filteredList.length > 0) {
        return (
            <div className="module grid projects-map module__projects-map">
                <div className="map-header grid">
                    <TagTitle title={title} />
                    {cta && (
                        <Link type="arrow" className="module_cta slow" path={cta.link}>
                            {cta.caption}
                        </Link>
                    )}
                </div>

                <div className="soluciones-container">
                    <ul className="soluciones-selector" data-details-overlay-visible={selectedProject !== null}>
                        {solutionsList.map((solution, idx) => {
                            return (
                                <li
                                    key={`solution-key-${idx}`}
                                    onClick={() => handleSolutionClick(solution.value)}
                                    data-selected-solution={selectedSolution === solution.value}
                                    data-solution-not-on-map={!solutionsOnMap.includes(solution.value)}
                                >
                                    <button>
                                        {typeof window != 'undefined' && window.innerWidth < 1280
                                            ? iconsMap[solution.value]
                                            : iconsMapLarge[solution.value]}
                                        <span className="title--xs">{solution.name}</span>
                                    </button>
                                </li>
                            );
                        })}
                    </ul>
                </div>

                <div className="map-container" data-detail-overlay-is-visible={selectedProject !== null}>
                    {<Map
                        pointsList={filteredList}
                        selectedSolution={selectedSolution}
                        changeSelectedPoint={changeSelectedPoint}
                        mapCenter={centerPoint}
                        changeMapCenter={setCenterPoint}
                        mapZoomValue={zoomValue}
                        changeZoomValue={setZoomValue}
                        culture={culture}
                    />}

                    {selectedProject &&
                        <ProjectDetailsOverlay project={selectedProject} closeAction={handleCloseOverlayClick} isLanding={isLanding} />}

                    <div className="project-details-close-overlay" onClick={handleCloseOverlayClick} />
                </div>
                {cta &&
                    <Link type="arrow" className="module_cta mobile slow" path={cta.link}>
                        {cta.caption}
                    </Link>
                }
            </div>
        );

    }
    else {
        return (
            <div className="module grid projects-map module__projects-map">
                <div className="map-header">
                    <TagTitle title={title} />
                </div>
            </div>
        );
    }
};



const ProjectDetailsOverlay = ({ project, closeAction, isLanding }) => {
    const { title, indexIntro, solution, country, region, cipher, unit, caption, headerImage, _contentPath, isInstalation } = project || {};
    const _self = React.createRef();

    const accionaDomain = isLanding ? "https://www.acciona.com" : "";

    if (headerImage) headerImage.url = headerImage.url.replace("http://media.acciona-agua.com", "https://mediacdn.acciona-agua.com");

    useEffect(() => {
        window.dataLayer.push({
            event: "ga_event",
            event_name: "project_map_unfold",
            event_category: "project map unfold",
            event_action: title.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize(),
            event_label: undefined,
            event_value: undefined,
            title: title.toLowerCase().normalize('NFD').replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi, "$1").normalize()
        });
    }, []); 

    return (
        <div className="project-details-overlay grid" ref={_self} data-is-visible='true'>
            <button className="close-overlay" onClick={() => closeAction(null)}>
                <CloseIconMedium />
            </button>
            {project && (
                <>
                    {headerImage &&
                        <div className="image-container">
                        <img src={headerImage.url ? headerImage.url.replace("//media/", "/media/") : iconsMap[solution.icon + 'Default']} alt={headerImage.alt ? headerImage.alt : headerImage.name} title={headerImage.title} />
                        </div>
                    }
                    <div className="detail-body" data-content-type={!cipher ? 'text' : 'cifra'}>
                        {indexIntro && !cipher && <div className="title--m" dangerouslySetInnerHTML={{ __html: indexIntro }}></div>}

                        {cipher && (
                            <>
                                <p className="cifras--m">
                                    {cipher}
                                    {unit && <small>{unit}</small>}
                                </p>
                                {caption && <p className="title--xs">{caption}</p>}
                            </>
                        )}
                    </div>

                    <div className="details-footer">
                        <span className="params">
                            <h3 className="name title--s">{title}</h3>
                            <span className="solution-location title--tag">
                                <Link path={accionaDomain + solution.url} target={isLanding ? "_blank" : ""}>
                                    {iconsMap[solution.icon]}
                                    <i>{solution.name}</i>
                                </Link>
                                {country && <span>|</span>}{country && <i>{country}</i>}
                            </span>
                        </span>
                        {!isInstalation && _contentPath && (
                            <Link path={accionaDomain + _contentPath} className="link" target={isLanding ? "_blank" : ""}>
                                {typeof window != 'undefined' && window.innerWidth < 1680 ? (
                                    <CircledArrow width="68" height="68" viewBox="0 0 68 68" />
                                ) : (
                                        <CircledArrow width="120" height="120" viewBox="0 0 68 68" />
                                    )}
                            </Link>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default ProjectsMap;
