import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { CheckboxGroup, Checkbox, RadioButton, TextInput, SelectInput, TextArea, NumberInput, Button, Lightbox, Wysiwyg } from 'React/components';
import { ReactComponent as IconCheck } from 'Icons/valid-16.svg';
import './styles.scss';
import { validateEmail, validateCIF, rndKeyGen } from 'React/helpers';
import { ReactComponent as Chevron } from 'Icons/chevron.svg';

// firstStep prop is used for internal validation only
const ContactFormAgua = ({ firstStep = 'initial', title, description, popup, translations, closeLightbox }) => {
    const [formStep, setFormStep] = useState(firstStep);
    const [companyNameError, setCompanyNameError] = useState('');
    const [contactPersonError, setContactPersonError] = useState('');
    const [contactPositionError, setContactPositionError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [cifError, setCifError] = useState('');
    const [supplyPointsError, setSupplyPointsError] = useState('');
    const [ATRError, setATRError] = useState('');
    const [checkboxError, setCheckboxError] = useState('');
    const [checkboxState, setCheckboxState] = useState(false);
    const [reCaptchaValue, setReCaptchaValue] = useState(false);

    const [visibleLightbox, setVisibleLightbox] = useState(false);
    const [lightboxContent, setLightboxContent] = useState('test');

    const handlePrivacyPolicyLightboxClick = (content) => {
        if (content !== '') {
            setLightboxContent(content);
            setVisibleLightbox(true);
        }
    };

    const {
        culture,
        labelCompanyName,
        labelTelephone,
        labelContactPerson,
        labelContactPosition,
        labelComments,
        labelGeneralInfo,
        labelCompanyNameValidation,
        labelCifValidation,
        labelContactPersonValidation,
        labelEmailValidation,
        labelSupplyPointsValidation,
        labelATRInterestedValidation,
        labelSend,
        labelCancel,
        labelFormSendOKTitle,
        labelFormSendOKDescription,
        labelIAcept,
        labelDataProtection,
        labelcheckboxError,
    } = translations;

    const handleSubmit = (event) => {
        var validationErrors = false;

        event.preventDefault();
        var companyName = event.target.companyName.value;
        var telephone = event.target.telephone.value;
        var contactPerson = event.target.contactPerson.value;
        var contactPosition = event.target.contactPosition.value;
        var email = event.target.email.value;
        var comments = event.target.Comments.value;

        if (!companyName) {
            validationErrors = true;
            setCompanyNameError(labelCompanyNameValidation);
        }
        else {
            setCompanyNameError('');
        }

        if (!contactPerson) {
            validationErrors = true;
            setContactPersonError(labelContactPersonValidation);
        }
        else {
            setContactPersonError('');
        }

        if (!contactPosition) {
            validationErrors = true;
            setContactPositionError(labelContactPositionValidation);
        }
        else {
            setContactPersonError('');
        }
        if (email) {
            if (!validateEmail(email)) {
                validationErrors = true;
                setEmailError(labelEmailValidation);
            }
            else {
                setEmailError('');
            }
        }
        else {
            validationErrors = true;
            setEmailError(labelEmailValidation);
        }

        if (!checkboxState) {
            validationErrors = true;
            setCheckboxError(labelcheckboxError);
        }
        else {
            setCheckboxError('');
        }

        if (!reCaptchaValue) {
            validationErrors = true;
        }

        if (!validationErrors) {

            var params = `companyName=${companyName}&telephone=${telephone}&contactPerson=${contactPerson}&contactPosition=${contactPosition}&email=${email}&comments=${comments}`;

            const xhrPages = new XMLHttpRequest();
            xhrPages.open('POST', "/SendAguaFormMail/", true);
            xhrPages.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
            xhrPages.onload = function (e) {
                if (xhrPages.readyState === 4) {
                    if (xhrPages.status === 200) {
                        setFormStep('sent');
                    } else {
                        console.error(xhrPages.statusText);
                    }
                }
            }.bind(this);

            xhrPages.send(params);
        }
    };

    const toggleCheckbox = () => {
        setCheckboxState((oldState) => !oldState);
    };

    function onChangeReCaptcha(value) {
        setReCaptchaValue(value);
    }

    if (formStep === 'initial')
        return (
            <form className="module grid contact_form" onSubmit={handleSubmit}>
                {title &&
                    <div className="form_header">
                        <legend className="form_title title--l">{title}</legend>
                        {description && <div className="form_desciption body--m" dangerouslySetInnerHTML={{ __html: description }} />}
                    </div>
                }
                <fieldset className="form_inputs">
                    {/* Datos Generales */}
                    <p className="form_section_title body--l">{labelGeneralInfo}</p>
                    {/* Razon social */}
                    <TextInput label={labelCompanyName} errorMsg={companyNameError} name="companyName" />
                    {/* Telefono */}
                    <NumberInput label={labelTelephone} name="telephone" />
                    {/* Persona de contacto */}
                    <TextInput label={labelContactPerson} errorMsg={contactPersonError} name="contactPerson" />
                    {/* Cargo Persona de contacto */}
                    <TextInput label={labelContactPosition} errorMsg={contactPositionError} name="contactPosition" />
                    {/* Email */}
                    <TextInput label='Email' errorMsg={emailError} name="email" />
                    {/* Comentarios */}
                    <TextArea label={labelComments} name="Comments" />


                    <div className="faux-flex">
                        <input
                            type="checkbox"
                            id="conditions_accepted"
                            value="true"
                            checked={checkboxState === true}
                            onChange={toggleCheckbox}
                            name="conditions_accepted"
                        />
                        <div className="faux-checkbox" onClick={toggleCheckbox} aria-hidden="true">
                            <Chevron className="checkbox-tick" />
                        </div>
                        {popup &&
                            <label
                                className="body--s for-terms-checkbox"

                                onClick={toggleCheckbox}
                            >{labelIAcept}
                            <a href="#" className="link--white" onClick={() => handlePrivacyPolicyLightboxClick(popup.text)}> {labelDataProtection}</a>
                            </label>
                        }

                        <Lightbox
                            key={rndKeyGen()}
                            isVisible={visibleLightbox}
                            closeMethod={() => setVisibleLightbox(null)}
                        >
                            <Wysiwyg html={lightboxContent} />
                        </Lightbox>
                    </div>
                    <span className="error_msg" style={{ marginTop: '23px' }} dangerouslySetInnerHTML={{ __html: checkboxError }}></span>

                    <ReCAPTCHA
                        className="reCaptcha"
                        sitekey="6LekF_8kAAAAAAXGixLBVKshqrNY8MZ0Q8qy_nIZ"
                        onChange={onChangeReCaptcha}
                        hl={culture}
                    />
                    <div className="form_controls_wrapper">
                        <Button
                            type="submit"
                            className="form_submit"
                            label={labelSend}
                            size="button--lg"
                            style_type="red"
                        />
                        <Button className="form_close" label={labelCancel} size="button--lg" onClick={() => closeLightbox(null)} />
                    </div>
                </fieldset>
            </form>
        );

    if (formStep === 'sent')
        return (
            <div className="form_feedback">
                <h2 className="form_title title--l">{title}</h2>
                <div className="feedback-msg">
                    <div className="icon-container">
                        <IconCheck width="32" height="32" viewBox="-2 -4 16 16" />
                    </div>
                    <h3 className="body--l">{labelFormSendOKTitle}</h3>
                    <h4 className="body--m">{labelFormSendOKDescription}</h4>
                </div>
            </div>
        );
};

export default ContactFormAgua;
